<template>
    <div v-if="checkIdCourtierActive()">
         <b-row>
           <b-col cols="12" class="mb-2">
             <h2 class="mb-0 text-uppercase font-weight-bolder">
               étape {{ getRisqueStatut('PROTECTIONJURIDIQUE') ? '6' : '5' }} : Confirmation de transmission de devis
             </h2>
           </b-col>
         </b-row>
         <b-row>
          <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                Découvrez le(s) devis proposé(s) par BubbleIn
               </h4>
          </b-col>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0 cursor-pointer" @click="showFile(etudeLocal.conseilpath)">
                 <b-img
                   style="margin-top:-5px"
                   
                   height="18"
                   :src="require('@/assets/images/icons/doc.png')"
                 />
                 Devis N° {{ etudeLocal.itemsRisque.ItemsRisque[0].devis.devis_no_interne }}
               </h4>
             </b-col>
             <b-col cols="12" class="mb-2" v-for="(doc,index) in documentSelected" :key="index">
               <h4 class="mb-0 cursor-pointer" @click="showFile(doc.document_id)">
                 <b-img
                   style="margin-top:-5px"
                   height="18"
                   :src="require('@/assets/images/icons/doc.png')"
                 />
                 <span> {{ doc.document.document_type.type}}</span>
                 <span v-if="doc.produit_garantie_id"> surcomplémentaire</span>
               </h4>
             </b-col>
          
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                Souhaitez-vous les transmettre sur l’espace projet du client ?
               </h4>
             </b-col>
          <b-col class="mb-2">
             <b-form>
                <b-form-group>
                  <b-form-radio :disabled="checkIdCourtierActive()" v-model="transmit_to_prospect" value="oui">Oui</b-form-radio>
                </b-form-group>
                <b-form-group>
                  <b-form-radio :disabled="checkIdCourtierActive()" v-model="transmit_to_prospect" value="non">Non</b-form-radio>
                </b-form-group>
                    
                <b-button @click="handleSubmitProspect" v-if="transmit_to_prospect === 'oui'" :disabled="disable_options_prospect" variant="primary" size="md">
                  <span class="align-middle">{{button_prospect_transmission}}</span>
                </b-button>
              </b-form>
            </b-col>
           </b-row>
           
         <div v-if="showTextAfterClick">
           <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                 Vous pouvez finaliser la souscription <u @click="goNextStep" class="cursor-pointer">en cliquant ici</u>.
               </h4>
             </b-col>
           </b-row>  
           <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-">
                 Il est par ailleurs désormais disponible dans votre espace devis ou dans la fiche personnelle de 
                 <u class="cursor-pointer" @click="redirectProspect(etudeLocal.Tiers.id,etudeLocal.Tiers.type,etudeLocal.Tiers.categorie)"> 
                   {{titleClass(etudeLocal.Tiers.civilite)}} {{etudeLocal.Tiers.prenom}} {{etudeLocal.Tiers.nom}}</u>. 
                
                 <!-- Vos pouvez finaliser la souscription <u @click="goNextStep" class="cursor-pointer">en cliquant ici</u>.La proposition est disponible dans votre espace projet -->
               </h4>
             </b-col>
           </b-row>
    
           <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                 Si vous souhaitez créer un nouveau devis pour le même client, <u class="cursor-pointer" @click="affecterProspect">cliquez ici</u>. 
               </h4>
             </b-col>
           </b-row>
         </div>
    </div>
    <div v-else>
         <b-row>
           <b-col cols="12" class="mb-2">
             <h2 class="mb-0 text-uppercase font-weight-bolder">
               étape {{ getRisqueStatut('PROTECTIONJURIDIQUE') ? '6' : '5' }} : Confirmation et envoi du devis
             </h2>
           </b-col>
         </b-row>
         <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                 Souhaitez-vous envoyer le devis par email ?
               </h4>
             </b-col>
             <b-col  class="mb-2">
              <b-form>
                <b-form-group>
                  <b-form-checkbox :disabled="disable_options_mail"  v-model="is_sent_option_client" >{{titleClass(etudeLocal.Tiers.civilite)}} {{etudeLocal.Tiers.prenom}} {{etudeLocal.Tiers.nom}} ({{etudeLocal.Tiers.moyen_contact.email}})</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox :disabled="disable_options_mail" v-model="is_sent_option_courtier" >Sur mon adresse email ({{courtierMail}})</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox :disabled="disable_options_mail" v-model="is_sent_option_non" >Non</b-form-checkbox>
                </b-form-group>
                <b-button @click="handleSubmitMail" v-if="is_sent_option_courtier || is_sent_option_client" :disabled="disable_options_mail" variant="primary" size="md">
                    <span class="align-middle">{{ button_mail_envoie }}</span>
                  </b-button>
              </b-form>
             </b-col>
           </b-row>
           <b-overlay  :show="showLoading" no-wrap />
         <div v-if="is_sent">
  
      
           <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                 Vous pouvez finaliser la souscription <u @click="goNextStep" class="cursor-pointer">en cliquant ici</u> ou le consulter et l'imprimer ci dessous.
               </h4>
             </b-col>
           </b-row>
           <b-row v-if="renderComponent">
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0 cursor-pointer" @click="showFile(etudeLocal.conseilpath)">
                 <b-img
                   style="margin-top:-5px"
                   
                   height="18"
                   :src="require('@/assets/images/icons/doc.png')"
                 />
                 Devis N° {{ etudeLocal.itemsRisque.ItemsRisque[0].devis.devis_no_interne }}
               </h4>
             </b-col>
             <b-col cols="12" class="mb-2" v-for="(doc,index) in documentSelected" :key="index">
               <h4 class="mb-0 cursor-pointer" @click="showFile(doc.document_id)">
                 <b-img
                   style="margin-top:-5px"
                   height="18"
                   :src="require('@/assets/images/icons/doc.png')"
                 />
                 <span> {{ doc.document.document_type.type}}</span>
                 <span v-if="doc.produit_garantie_id"> surcomplémentaire</span>
               </h4>
             </b-col>
           </b-row>
    
           <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-">
                 Il est par ailleurs désormais disponible dans votre espace devis ou dans la fiche personnelle de 
                 <u class="cursor-pointer" @click="redirectProspect(etudeLocal.Tiers.id,etudeLocal.Tiers.type,etudeLocal.Tiers.categorie)"> 
                   {{titleClass(etudeLocal.Tiers.civilite)}} {{etudeLocal.Tiers.prenom}} {{etudeLocal.Tiers.nom}}</u>. 
                
                 <!-- Vos pouvez finaliser la souscription <u @click="goNextStep" class="cursor-pointer">en cliquant ici</u>.La proposition est disponible dans votre espace projet -->
               </h4>
             </b-col>
           </b-row>
    
           <b-row>
             <b-col cols="12" class="mb-2">
               <h4 class="mb-0">
                 Si vous souhaitez créer un nouveau devis pour le même client, <u class="cursor-pointer" @click="affecterProspect">cliquez ici</u>. 
               </h4>
             </b-col>
           </b-row>
         </div>
  
  
  
    </div>
  </template>
  
  <script>
  
  import vSelect from "vue-select";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import flatPickr from "vue-flatpickr-component";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { mapGetters } from "vuex";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import {
    BOverlay,
   BImg,
   BRow,
   BCol,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormRadioGroup,
   BFormRadio,
   BFormCheckboxGroup,
   BFormCheckbox,
   BTabs,
   BTab,
   BFormSelect,
   BTable,
   BTableSimple,
   BThead,
   BTr,
   BTh,
   BTd,
   BTbody,
   BButton,
   BCard,
   BCardText,
   BListGroup,
   BListGroupItem,
   BFormTextarea,BForm
  } from "bootstrap-vue";
  import { required, email } from "@validations";
  import { sendMail } from '@/shared/utils/mail-protection-juridique'
  import moment from 'moment'
  
  export default {
   components: {
     ValidationProvider,
     ValidationObserver,
     BImg,
     BRow,
     BCol,
     BFormGroup,
     BFormInput,
     BForm,
     vSelect,
     BFormInvalidFeedback,
     // eslint-disable-next-line vue/no-unused-components
     ToastificationContent,
     BFormRadioGroup,
     BFormCheckbox,
     BFormRadio,
     BFormCheckboxGroup,
     BTabs,
     BTab,
     flatPickr,
     BFormSelect,
     BTable,
     BTableSimple,
     BThead,
     BTr,
     BTh,
     BTd,
     BTbody,
     BButton,
     BCard,
     BCardText,
     BListGroup,
     BListGroupItem,
     BFormTextarea,
    BOverlay
   },
   data() {
   return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
       etudeLocal:null,
       renderComponent:true,
       rl:null,
       documentSelected:[],
       is_sent_option_client:false,
       is_sent_option_courtier:true,
       is_sent_option_non:false,
       disable_options_mail:false,
       is_sent:false,
       button_mail_envoie:"envoi de l'email",
       showLoading:false,
       indexRisque:0,
       courtierMail: '',
       documnet_ids:[],
       dataClientToSend :[],
       button_prospect_transmission: "Transmettre sur l'espace projet",
       transmit_to_prospect: 'oui', // Déclarer et initialiser la variable transmit_to_prospect
       disable_options_prospect: false,
      showTextAfterClick:false
     };
   },
   computed: {
     ...mapGetters(["getEtude"]),
     ...mapGetters(["getItemsRisque"]),
     ...mapGetters(['getSelectedRisque']),
  
   },
   watch: {
     getEtude: {
         handler: function (val, oldVal) {
           this.etudeLocal = val;
           this.forceRerender()
         },
         deep: true,
         immediate:true
     },
     getItemsRisque: {
       handler: function (val, oldVal) {
         this.rl = this.getItemsRisque.ItemsRisque[0];
         this.selectProduitOption(this.rl)
       },
       deep:true
     },
     'is_sent_option_client':{
      handler(val){
        if(val && !this.is_sent_option_courtier){
          this.is_sent_option_non = false;
          this.button_mail_envoie ="envoi de l'email";
        }else if(val && this.is_sent_option_courtier){
          this.is_sent_option_non = false;
          this.button_mail_envoie = "ENVOI DES EMAILS";
  
        }else if(!val && this.is_sent_option_courtier){
  
          this.button_mail_envoie ="envoi de l'email";
        }
        
      }
     },
     'is_sent_option_courtier':{
      handler(val){
        if(val && !this.is_sent_option_client){
          this.is_sent_option_non = false;
          this.button_mail_envoie ="envoi de l'email";
        } else if(val && this.is_sent_option_client){
          this.is_sent_option_non = false;
          this.button_mail_envoie = "ENVOI DES EMAILS";
  
        }else if(!val && this.is_sent_option_client){
  
          this.button_mail_envoie ="envoi de l'email";
        }
        
      }
      
     },
     'is_sent_option_non':{
      handler(val){
        if(val){
          this.is_sent_option_courtier = false;
          this.is_sent_option_client = false;
          this.is_sent = true;
        }else{
          this.is_sent = false;
  
        }
      }
     },
     getSelectedRisque: {
        handler(val) {
          this.indexRisque = val
        }
      }
   },
   created(){
      this.getMailMoyenContactPersonnePhys();
      console.log(this.idCourtierActive)
  
   },
   methods: {
    checkerDateEffet() {
      let produitPresente   = this.rl.produitsFiltred.filter(pr => pr.tarif.presente)
      let uniqueProducts    = _.uniqBy(produitPresente, 'produit.id');
      let dtValidite      = []
      uniqueProducts.forEach(element => {
          const assureurName = element.produit.assureur.nom_assureur;
          if(assureurName == 'ECA_ASSURANCES') {
            if(this.getEtude.itemsRisque.ItemsRisque[0].objets.objet_protection_juridique.ria) {
              if (this.getEtude.itemsRisque.ItemsRisque[0].devis.type_vente == 'optout') {
                dtValidite.push(moment(this.getEtude.itemsRisque.ItemsRisque[0].objets.objet_protection_juridique.date_effet, 'DD/MM/YYYY').subtract(35, 'days'))
              } else {
                dtValidite.push(moment(this.getEtude.itemsRisque.ItemsRisque[0].objets.objet_protection_juridique.date_effet, 'DD/MM/YYYY').subtract(34, 'days'))
              }
            }
            else {
              dtValidite.push(moment().add(30, 'days'))
            }
          }
      })
  
      return moment.min(dtValidite).format('DD/MM/YYYY')
      },
    handleSubmitMail(){
      this.sendMail();
      
    },
      handleSubmitProspect() {
      this.sendMail(true);
      this.disable_options_prospect=true
    },
    checkIdCourtierActive() {
        return this.idCourtierActive == 10;
    },
    async sendMail(fromEspaceProspect = false){
      if(this.is_sent_option_courtier || this.is_sent_option_client){
        this.showLoading = true;
        let emails_to = [];
        let dtValidite = this.checkerDateEffet()
        let current_user = this.currentUser
        let prospect = this.getEtude.Tiers
        let devisId = this.getEtude.itemsRisque.ItemsRisque[0].devis.id
        console.log( this.dataClientToSend)
          let data = {}
          
          await this.$http
            .get(`ecaglobal/generateDevisKeyEncrypt/${devisId}`)
            .then(response => {
              let dataFront = {
                numeroProject: this.getEtude.itemsRisque.ItemsRisque[0].devis.devis_no_interne,
                risque: this.getEtude.itemsRisque.ItemsRisque[0].risque.label,
                dateProjet: moment(String(new Date())).format('DD/MM/YYYY'),
                dateValidite: dtValidite,
                conseiller: current_user.courtier_user[0].courtier.personne_morale.denomination_commercial,
                courtier: this.etudeLocal.itemsRisque.ItemsRisque[0].devis.user.personne_physique,
                url: response.data,
                espaceClient: this.dataClientToSend,
                espaceProspect: fromEspaceProspect
              }
               if(fromEspaceProspect){
                  emails_to.push(prospect.moyen_contact.email)  
              }else{
                  if(this.is_sent_option_client) emails_to.push(prospect.moyen_contact.email)
                  if(this.is_sent_option_courtier) emails_to.push(this.courtierMail)
              }
              this.documnet_ids = []
              this.documnet_ids = this.documentSelected.map(row => row.document_id);
              this.documnet_ids.push(this.getEtude.conseilpath)
              data = {
                civilite: prospect.civilite,
                nom: prospect.nom,
                prenom: prospect.prenom,
                subject: "Votre devis d'assurance",
                message: sendMail(dataFront),
                email_to: emails_to,
                document_ids: this.documnet_ids,
                isReplyToCourtier: 1,
                hideLogoLpdc: 1,
                devisId: devisId,
                step: 'souscription',
                espaceProspect: fromEspaceProspect,
                modeTrans : this.getModeTrans(fromEspaceProspect, this.is_sent_option_client, this.is_sent_option_courtier)
              }
  
   
            })
            .catch(err => {
              console.log(err)
            })
              this.showLoading = true;
  
              this.$http
                  .post('ecaglobal/send_mail_to_client', data)
                  .then(r => {
                    if (r.data.success) {
                      this.button_mail_envoie = data.email_to.length > 1 ? "Emails envoyés" : "Email envoyé";
                      this.is_sent = true;
                      this.disable_options_mail = true;
                      this.disable_options_prospect =true
                      this.button_prospect_transmission= "Transmis sur l'espace projet";
                      this.showTextAfterClick =true
                      this.messageToast('Informations transmises avec succès',"Succès", "success")
                      this.showLoading = false;
                      console.log('success Mail') 
                    } else {
                      console.log('error Mail')
                      this.messageToast('Une erreur est survenue', 'Erreur', 'error')
                      this.showLoading = false;
  
                    }
                  })
                  .catch(err => {
                  this.disable_options_prospect=false
                  this.button_prospect_transmission= "Transmettre sur l'espace projet";
                  this.showTextAfterClick =true
                  this.messageToast('Une erreur est survenue', 'Erreur', 'error')
                  console.log(err)
                this.showLoading = false;
  
                  })
            
      }
    },
     getRisqueStatut(value) {
       return value ? this.formatText(this.rl.risque.label) == value : ''
     },
     getMailMoyenContactPersonnePhys(){
      let email = this.etudeLocal.itemsRisque.ItemsRisque[0].devis.user.personne_physique.moyen_contact.email
      let courtierActive  = this.currentUser.courtier_user[0].courtier.id
      this.idCourtierActive = courtierActive
  
      if(email) {
        this.courtierMail = email
      } else {
        this.$http.get('eca_global/fetchMailMoyenContactByPersonnePhys/' + this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.id).then((res)=>{
          if(res.data.email){
            this.courtierMail = res.data.email
          }else{
            this.courtierMail = this.currentUser.courtier_user[0].courtier.personne_morale.moyen_contact.email;
          }
        })
      }
     },
     forceRerender() {
       this.renderComponent = false;
       this.$nextTick(() => {
       this.renderComponent = true;
       });
     },
     selectProduitOption(risque) {
      this.documentSelected = [] 
      let produitPresente = risque.produitsFiltred.filter(pr => pr.tarif.presente)
      let uniqueProducts = _.uniqBy(produitPresente, 'produit.id');
        uniqueProducts.forEach(element => {
           element.produit.document_assureur.map((doc)=>{
             if(doc.document.type_id == 59 && element.produit.id == 27){
                this.documentSelected.push(doc)
             }else{
              if(doc.produit_garantie_id==null && doc.document.type_id != 59 && doc.document.type_id !=108){
               this.documentSelected.push(doc)
               }
             }
             
           })
        })
        produitPresente.map((prodpresent)=>{
           prodpresent.garanties.map((gn)=>{
             let result=_.find(gn.garantieniveaux, function(gnr) { return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel=='Oui' });
             if(result && prodpresent.garanties[1].produit_garantie.document_assureur.length>0){
               prodpresent.garanties[1].produit_garantie.document_assureur.map((dc)=>{
                 if(dc.document_id){
                   let findDoc = this.documentSelected.find((document) => {
                     return document.document_id == dc.document_id
                   })
                   if(!findDoc) 
                     this.documentSelected.push(dc)
                 }
               })
             }
           })
        })
     },
     titleClass(word){
      return word ?  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '';
     },
     validate() {
       return new Promise((resolve, reject) => {
         resolve(true)
       });
     },
     goNextStep(){
     this.clearDataSendMail();
  
       let etudeId=this.etudeLocal.etudeID
       this.$store.dispatch('clearData');
       this.$router.push({ name: 'projets-souscription' , params: {  id: etudeId }})
     },
     showFile(documentId){
       this.$http.get(`ecaglobal/downloadBase64/${documentId}`)
           .then(res => 
           { 
               let pdfWindow = window.open("");
               pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + res.data +"'></iframe>");
           }
       )
     },
     clearDataSendMail(){
       // clear data 
       this.is_sent_option_client=false
       this.is_sent_option_courtier=true
       this.is_sent_option_non=false
       this.disable_options_mail=false
       this.is_sent=false
       this.button_mail_envoie="envoi de l'email"
       this.emails_to = [];
     },
     affecterProspect() {
     this.clearDataSendMail();
       let tmpProspect = this.etudeLocal.Tiers
       try {
         this.$store.dispatch('clearData');
         this.$store.commit('setProspect', {
           Tiers: tmpProspect
         })
         this.$router.push({ name: 'projets-proposition' })
       } catch (e) {
         this.errors = e.response.data.errors
       }
     },
     redirectProspect(id,type,categorie){
       if (categorie.toLowerCase() === 'entreprise') {
         this.$router.push({
           name: 'tiers-list',
           params: {
             'id': id,
             'type': type,
             'interfaceChosen': 'Entreprise'
           }
         })
       }else if(categorie.toLowerCase() === 'professionnel' || categorie.toLowerCase() === 'particulier') {
         this.$router.push({
           name: 'tiers-list',
           params: {
             'id': id,
             'type': type,
             'interfaceChosen': 'Professionnel'
           }
  
         })
       }
     }
   },
  };
  </script>
  