import { render, staticRenderFns } from "./2Roues.vue?vue&type=template&id=06fa8c17&scoped=true&"
import script from "./2Roues.vue?vue&type=script&lang=js&"
export * from "./2Roues.vue?vue&type=script&lang=js&"
import style0 from "./2Roues.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./2Roues.vue?vue&type=style&index=1&id=06fa8c17&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fa8c17",
  null
  
)

export default component.exports