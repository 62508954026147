<template>
   <div>
        <b-row>
            <b-col cols="12" class="mb-2">
              <h2 class="mb-0 text-uppercase font-weight-bolder">
                  étape 2 : recueil des besoins
              </h2>
              <h6>
                  Renseignez les besoins exprimés
              </h6>
            </b-col>
        </b-row>
        <b-row >
          <b-col cols="12" class="">
             <sante-tns-component ref="saisie" v-if="getRisqueStatut('SANTETNS')" @on-validate="onStepValidate"></sante-tns-component>
             <sante-seniors-component ref="saisieSanteSenior"  v-else-if="getRisqueStatut('SANTESENIORS')" @on-validate="onStepValidate"></sante-seniors-component>
             <sante-deces-component ref="saisieDeces" v-else-if="getRisqueStatut('DECES')" @on-validate="onStepValidate"></sante-deces-component>
             <sante-actifs-component ref="saisieSanteActif"  v-else-if="getRisqueStatut('SANTEACTIFS')" @on-validate="onStepValidate"></sante-actifs-component>
             <roues-component ref="saisie2Roues"  v-else-if="getRisqueStatut('2-ROUES')" @on-validate="onStepValidate"></roues-component>
             <emprunteur-component ref="saisieEmprunteur" v-else-if="getRisqueStatut('EMPRUNTEUR')" @on-validate="onStepValidate"></emprunteur-component>
             <ijh-component v-else-if="getRisqueStatut('INDEMNITEJOURNALIEREHOSPITALIERE')"  @on-validate="onStepValidate"/>
             <protection-juridique-component ref="saisieProtectionJuridique"  v-else-if="getRisqueStatut('PROTECTIONJURIDIQUE')" @on-validate="onStepValidate"></protection-juridique-component>
            </b-col>
        </b-row>
   </div>
</template>

<script>
import EmprunteurComponent from "./Risque/Emprunteur.vue";
import SanteTnsComponent from "./Risque/SanteTns.vue";
import SanteSeniorsComponent from "./Risque/SanteSeniors.vue";
import SanteActifsComponent from "./Risque/SanteActifs.vue";
import RouesComponent from "./Risque/2Roues.vue";
import ProtectionJuridiqueComponent from "./Risque/ProtectionJuridique.vue";
import ijhComponent from './Risque/SanteIJH.vue'
import SanteDecesComponent from './Risque/SanteDeces.vue';


import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    // UI
    EmprunteurComponent,
    SanteTnsComponent,
    SanteSeniorsComponent,
    RouesComponent,
    ProtectionJuridiqueComponent,
    ijhComponent,
    SanteDecesComponent,
    SanteActifsComponent,

    // BTV
    BRow,
    BCol,
  },
  data() {
    return {
        test:false,
        rl:null
    };
  },
  created() {
    // this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  computed: {
    ...mapGetters(["getItemsRisque"]),
  },
  watch: {
    getItemsRisque: {
        handler: function (val, oldVal) {
            this.rl = this.getItemsRisque.ItemsRisque[0];
        },
        deep:true
    },
  },
  methods: {
    validate() {
      alert('secondevalidate')
      return this.$refs['saisie'].validate();
    },
    onStepValidate(){
        alert('secondestepvalidate')
    },
    getRisqueStatut(value){
      return value ?  this.formatText(this.rl.risque.label)==value : ''
    },
    formatText(str) {
      var map = {
          '-' : ' ',
          '-' : '_',
          'a' : 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
          'e' : 'é|è|ê|ë|É|È|Ê|Ë',
          'i' : 'í|ì|î|ï|Í|Ì|Î|Ï',
          'o' : 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
          'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
          'c' : 'ç|Ç',
          'n' : 'ñ|Ñ'
      };
      for (var pattern in map) {
          str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      };
      return str.split(' ').join('').toUpperCase()
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

