<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-modal id="modal-display-prospect-data" ref="modal-display-prospect-data" hide-footer centered size="lg" title="Informations Complémentaires" no-close-on-backdrop>
      <b-card-body style="padding: 0 1.5rem 1.5rem">
        <b-form class="px-2" @submit.prevent="handleSubmitCheckData(onSubmit)" @reset.prevent="resetForm">
          <b-alert variant="warning" show class="mb-2">
            <div class="alert-body">
              <span> Merci de renseigner les informations de contact de votre [prospect/client] </span>
            </div>
          </b-alert>
          <b-form-group label="Email*" label-for="email">
            <b-form-input id="email" v-model="$v.prospectLocal.moyen_contact.email.$model" trim placeholder="Email" />
            <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.email.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group label="Téléphone Portable*" label-for="tel">
            <b-form-input id="nom_document" v-model="$v.prospectLocal.moyen_contact.tel.$model" trim placeholder="Téléphone Portable" v-mask="'## ## ## ## ##'" />
            <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.tel.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-form-group> -->

          <b-form-group label="Numéro de téléphone " label-for="phone">
            <b-row>
              <b-col sm="3" class="pr-0">
                <v-select v-model="$v.prospectLocal.moyen_contact.indicatif_tel.$model" :close-on-select="true" :clearable="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif" :reduce="indicatif => indicatif.indicatif" input-id="indicatif" label="indicatif" class="indicatif-chooser-tarif-santesenior w-100">
                  <template #option="{ indicatif, code }">
                    <span> {{ `${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">Aucune indicatif disponible.</div>
                </v-select>
              </b-col>
              <b-col sm="9" class="pl-0">
                <cleave id="phone" v-model="$v.prospectLocal.moyen_contact.tel.$model" :options="options.phone" :raw="false" class="form-control" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none" placeholder="ex: 06 00 00 00 00" />
              </b-col>
              <b-col sm="12">
                <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.tel.$error"> Le numéro de téléphone est obligatoire </b-form-invalid-feedback>
              </b-col>
              <b-col sm="12">
                <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.indicatif_tel.$error"> L'indicatif téléphonique est obligatoire </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="12">
              <div class="demo-inline-spacing">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="addEmailAndTel"> Enregistrer </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-modal>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 4 : préconisation d'une formule</h2>
        <h6>Préconisez une formule parmi les propositions (commentaire obligatoire)</h6>
      </b-col>
    </b-row>
    <b-row class="pricing-card" v-for="(rl, index) in resumeLocal" :key="index + 'content'">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row>
          <b-col md="12" lg="12" xl="6" class="resume" v-for="(pr, val) in filtreProduit(rl.produitsFiltred)" :key="val + 'produits'">
            <b-card align="center" :header="`${pr.produit.produit_nom} - ${pr.produit.assureur.nom_com_assureur}`" class="text-center">
              <div class="d-flex flex-column bd-highlight">
                <div class="pt-1 bd-highlight">
                  <h5>{{ pr.tarif.formule_commercial }}</h5>
                </div>
                <!-- <div class="pt-1 bd-highlight">
                  <span class="d-block" style="font-size: 10px"> {{ pr.tarif.formule_descriptif }}</span>
                </div> -->
                <div class="bd-highlight">
                    <!-- <b-table-simple small responsive class="table-emprunteur">
                        <b-thead >
                            <b-tr>
                                <b-th colspan="1" style="width: 10px"></b-th>
                                <b-th colspan="4">Prêt principal</b-th>
                                <b-th colspan="4" v-if="countPrets.secondaire > 0">Prêt secondaire</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th colspan="1" style="width: 10px"></b-th>
                                <b-th :colspan="countPrets.principal == 1 ? 4 : 2">Emprunteur</b-th>
                                <b-th v-if="countPrets.principal > 1 " :colspan="countPrets.principal > 1 ? 2 : 0">Co-assuré</b-th>
                                <b-th v-if="countPrets.secondaire > 0" :colspan="countPrets.secondaire == 1 ? 4 : 2">Emprunteur</b-th>
                                <b-th v-if="countPrets.secondaire > 1" :colspan="countPrets.secondaire > 1 ? 2 : 0">Co-assuré</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th colspan="1"></b-th>
                                <b-td colspan="2" v-for="(tarifPret, val) in pr.tarif.detailTarifByPret" :key="val + 'pret-total'">
                                    <div class="plan-price">
                                        <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                                        <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.2rem"> {{ tarifPret.cotisation_annulle_1ere_annee }} </span>
                                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ rl.devis.fractionnement.toLowerCase() }}</sub>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th >Décés/PTIA</b-th>
                                <template v-for="(tarifPret, val) in pr.tarif.detailTarifByPret">
                                    <b-td :key="'PTIA-%'+val">{{ tarifPret.garanties_et_franchises[1].value }}</b-td>
                                    <b-td :key="'PTIA-€'+val">{{ tarifPret.deces_ptia }}€</b-td>
                                </template>
                            </b-tr>
                             <b-tr>
                                <b-th >IPT/ITT</b-th>
                                <template v-for="(tarifPret, val) in pr.tarif.detailTarifByPret">
                                    <template v-if="tarifPret.itt_ipt != '-'">
                                        <b-td :key="'ITT-%'+val">{{ tarifPret.garanties_et_franchises[2].value }}</b-td>
                                        <b-td :key="'ITT-€'+val">{{ tarifPret.itt_ipt }}€</b-td>
                                    </template>
                                    <template v-else>
                                        <b-td :key="'ITT-empty'+val" colspan="2">--</b-td>
                                    </template>
                                </template>
                            </b-tr> 
                             <b-tr>
                                <b-th >IPP</b-th>
                                <template v-for="(tarifPret, val) in pr.tarif.detailTarifByPret">
                                    <template v-if="tarifPret.ipp != '-'">
                                        <b-td :key="'IPP-%'+val">-</b-td>
                                        <b-td :key="'IPP-€'+val">{{ tarifPret.ipp }}€</b-td>
                                    </template>
                                    <template v-else>
                                        <b-td :key="'IPP-empty'+val" colspan="2">-</b-td>
                                    </template>
                                </template>
                            </b-tr> 
                            <b-tr>
                                <b-th >Franchise</b-th>
                                <template v-for="(tarifPret, val) in pr.tarif.detailTarifByPret">
                                    <template v-if="tarifPret.garanties_et_franchises[3].value">
                                        <b-td :key="'Franchise-jrs'+val" colspan="2">{{ tarifPret.garanties_et_franchises[3].value }}</b-td>
                                    </template>
                                    <template v-else>
                                        <b-td :key="'IPP-empty'+val" colspan="2">-</b-td>
                                    </template>
                                </template>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->
                  <!-- <div v-for="(DTBP, indexDTBP) in pr.tarif.detailTarifByPret" :key="indexDTBP + ' - GARANTIES ET FRANCHISES'" class="mb-3">
                    <b-list-group v-for="(garantieEtFranchise, indexgarantie) in DTBP.garanties_et_franchises" :key="indexDTBP + ' - ' + indexgarantie + ' - GARANTIES ET FRANCHISES' ">
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0" >
                        <span v-if="garantieEtFranchise.isParent" :style="garantieEtFranchise.style" class="d-block pb-1"> {{ garantieEtFranchise.value }} </span>
                        <b-list-group v-else :style="garantieEtFranchise.style">
                          <b-list-group-item class="border-0 no-hover-bg py-0">
                            <div class="d-flex bd-highlight align-items-center">
                              <div class="w-75 bd-highlight"> {{ garantieEtFranchise.key }} </div>
                              <div class="flex-shrink-1 bd-highlight text-left"> {{ garantieEtFranchise.value }} {{  garantieEtFranchise.price ? " - " + garantieEtFranchise.price : "" }}</div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-list-group-item>
                    </b-list-group>
                    <div class="py-1"> 
                      <b-list-group v-for="(garantieEtFranchise, indexgarantie) in DTBP.options" :key="indexDTBP + ' - ' + indexgarantie + ' - OPTIONS' ">
                        <b-list-group-item class="border-0 p-0 no-hover-bg py-0" >
                          <b-list-group v-if="!garantieEtFranchise.isParent" :style="garantieEtFranchise.style">
                            <b-list-group-item class="border-0 no-hover-bg py-0">
                              <div class="d-flex bd-highlight align-items-center">
                                <div class="w-75 bd-highlight">{{ garantieEtFranchise.key }}</div>
                                <div class="flex-shrink-1 bd-highlight">
                                  <feather-icon :icon="garantieEtFranchise.value" size="16" :color="garantieEtFranchise.style"/>
                                </div>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                    <hr>
                    <b-list-group class="text-primary">
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0" >
                        <div class="d-flex bd-highlight align-items-center">
                          <div class="w-75 bd-highlight"> <strong>Cotisation annuelle (1ére année)</strong> </div>
                          <div class="flex-shrink-1 bd-highlight text-left"> <strong>{{ DTBP.cotisation_annulle_1ere_annee }}€</strong> </div>
                        </div>
                      </b-list-group-item>
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0" >
                        <div class="d-flex bd-highlight align-items-center">
                          <div class="w-75 bd-highlight"> <strong>Budget</strong> </div>
                          <div class="flex-shrink-1 bd-highlight text-left"> <strong>{{ DTBP.budget }}€</strong> </div>
                        </div>
                      </b-list-group-item>
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0" >
                        <div class="d-flex bd-highlight align-items-center">
                          <div class="w-75 bd-highlight"> <strong>Budget couverture de prêt / 8ans</strong> </div>
                          <div class="flex-shrink-1 bd-highlight text-left"> <strong>{{ DTBP.budget_couverture_de_pret_sur_8_ans }}€</strong> </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div> -->
                  <b-row style="font-size: 12px;" class="text-left px-1">
                    <b-col sm="12" v-for="(DTBP, indexDTBP) in $_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc')" :key="indexDTBP + ' - GARANTIES ET FRANCHISES'">
                      <b-row>
                      <template v-if="DTBP.pret == 'Principal'">
                        <b-col  sm="12" class="text-center pt-3">
                            <div style="margin-bottom:10px;" class="bd-highlight">
                              <div class="annual-plan mb-0">
                                <div class="plan-price">
                                  <span class="pricing-basic-value font-weight-bolder text-primary pr-1" style="font-size: 1.5rem">{{ `${DTBP.assure}` }}</span>
                                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem"> {{ Number(pr.tarif.totalBase[DTBP.type_assure == 'Assuré' ? 'Assure' : 'Co-assure'].price).toFixed(2) }} </span>
                                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/an</sub>
                                </div>
                              </div>
                            </div>
                            <h6> {{ pr.tarif.formule =='CI' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : pr.tarif.formule =='CI' && pr.produit.assureur_id == '2632' ?  "cotisation fixe annuelle sur l'ensemble du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '2632'? "Cotisation annuelle moyenne calculée sur le montant total du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : "" }} </h6> 
                        </b-col>
                      </template>

                        <b-col md="12" class="pb-1 pt-2 px-0">
                          <strong>{{ `Prêt ${DTBP.pret.toLowerCase()}` }}</strong>
                        </b-col>
                        <b-col sm="12" class="border-bottom-primary border-top-primary border-2 py-1">
                          <b-row class="text-primary">
                            <b-col sm="5" class="p-0"><strong>Garanties, franchise</strong></b-col>
                            <b-col sm="3" class="p-0"><strong>Quotités - délais</strong></b-col>
                            <b-col sm="2" class="p-0"><strong>Statut</strong></b-col>
                            <b-col sm="2" class="p-0"><strong>Coût total</strong></b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row >
                        <b-col sm="12" v-for="(garantieEtFranchise, indexgarantie) in DTBP.garanties_et_franchises" :key="indexDTBP + ' - ' + indexgarantie + ' - GARANTIES ET FRANCHISES' ">
                          <b-row :class="indexgarantie == 0 ? 'mt-1' : ''">
                            <template v-if="!garantieEtFranchise.isParent && garantieEtFranchise.isShowing">
                              <b-col sm="5" class="p-0">{{ garantieEtFranchise.key }}</b-col>
                              <b-col sm="3" class="p-0" :style="garantieEtFranchise.style">{{ garantieEtFranchise.value }}</b-col>
                              <b-col sm="2" class="p-0"> 
                                <feather-icon :icon="garantieEtFranchise.icon.feather" size="16" :color="garantieEtFranchise.icon.color"/>
                              </b-col>
                              <b-col sm="2" class="p-0"> 
                                {{ garantieEtFranchise.price }}
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                        <b-col sm="12" v-for="(garantieEtFranchise, indexgarantie) in DTBP.options" :key="indexDTBP + ' - ' + indexgarantie + ' - OPTIONS' ">
                          <b-row :class="indexgarantie == 1 ? 'pt-1' : ''">
                            <template v-if="!garantieEtFranchise.isParent">
                              <b-col sm="5" class="p-0">{{ garantieEtFranchise.key }}</b-col>
                              <b-col sm="3" class="p-0">-</b-col>
                              <b-col sm="2" class="p-0"> 
                                <feather-icon :icon="garantieEtFranchise.value" size="16" :color="garantieEtFranchise.style"/>
                              </b-col>
                              <b-col sm="2" class="p-0"> 
                                {{ garantieEtFranchise.price != '' ? garantieEtFranchise.price : '-' }}
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                        <b-col sm="12">
                          <b-row>
                            <b-col sm="12" class="p-0">
                              <hr>
                            </b-col>
                            <b-col sm="5" class="p-0">TAEA</b-col>
                            <b-col sm="2" class="p-0" offset="5">{{ DTBP.taea != "-" ? Number(DTBP.taea).toFixed(2)+"%" : DTBP.taea }}</b-col>
                            <b-col sm="5" class="p-0">Budget par prêt</b-col>
                            <b-col sm="2" class="p-0" offset="5">{{ DTBP.budget }}€</b-col>
                            <b-col sm="5" class="p-0">Budget sur 8 ans par prêt</b-col>
                            <b-col sm="2" class="p-0" offset="5">{{ DTBP.budget_couverture_de_pret_sur_8_ans }}€</b-col>
                          </b-row>
                          <b-row class="text-primary" v-if="DTBP.pret == 'Secondaire' || (DTBP.pret == 'Principal' && countPrets.secondaire <= 0)">
                            <b-col sm="12" class="p-0">
                              <hr class="border-top-primary">
                            </b-col>
                            <b-col sm="5" class="p-0"><strong>Budget total / assuré</strong></b-col>
                            <b-col sm="2" class="p-0" offset="5"><strong>{{ initBudget(pr.tarif.detailTarifByPret, DTBP.is_assure, 'budget') }}€</strong></b-col>
                            <!-- <b-col sm="5" class="p-0" ><strong>Budget sur 8 ans</strong></b-col>
                            <b-col sm="2" class="p-0" offset="5"><strong>{{ initBudget(pr.tarif.detailTarifByPret, DTBP.is_assure, 'budget_couverture_de_pret_sur_8_ans') }}€</strong></b-col> -->
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <template #footer><!-- Exigence -->
                <b-form-textarea placeholder="Commentaires..." v-model="pr.tarif.commentaire" rows="3" maxlength="255"> </b-form-textarea>
                <!-- buttons -->
                <b-button block class="mt-2" @click="choisirProduit(index, pr.tarif.tarification_id)" :variant="pr.tarif.preconise ? 'primary' : 'outline-primary'">
                  <span v-if="pr.tarif.preconise">Préconisée</span>
                  <span v-else>Préconiser</span>
                </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col  lg="12">
        <app-collapse id="collapse-besoin" type="margin" :is-visible="true" >
          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>Lexique<u></h4>">
          <ul>
            <li> Les montants affichés au niveau des garanties, options et budgets (hors budget sur 8 ans) portent sur la durée total du prêt.</li>
            <li><feather-icon icon="MinusIcon" size="24" /> : Garantie non envisagée</li>
            <li><feather-icon icon="CheckIcon" size="24" color="green"/> : Soit la garantie est envisagée et proposée soit la garantie est incluse</li>
            <li><feather-icon icon="XIcon" size="24" color="red"/> : La garantie envisagée n’est pas proposée (ou ne l’est pas pour cet assuré/ce projet)</li>
          </ul>
            <p>Pour Harmonie mutuelle, le budget sur 8 ans est calculé à partir des cotisations annuelles fractionnées.</p>
            <p>Chez Harmonie mutuelle, les options (EXO, DOS/PSY) sont sélectionnées par assuré (pas de distinction par prêt). Si une option est demandée sur un prêt, elle sera demandée sur les deux prêts.</p>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <b-row style="margin-top:50px;">
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
        <b-button v-if="canNext" variant="primary" class="float-right" size="lg" @click="selectProduit"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BOverlay, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, VBTooltip, BForm, BCardBody, BAlert, BBadge } from 'bootstrap-vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Cleave from 'vue-cleave-component'
//import { codeIcon } from './code'

export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BForm,
    BCardBody,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BBadge
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      countPrets : {
        principal: 0,
        secondaire: 0
      },
      checkerExplicationOptions: { isOption1: false, isOption2: false },
      resumeLocal: [],
      indexLocal: 0,
      indexRisque: 0,
      hasProduct: false,
      hasCommentedProduit: false,
      hasSelectedProduit: false,
      showLoading: false,
      indicatif: [],
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true
        }
      },
      prospectLocal: {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        date_naissance: null,
        type: null,
        numeroOrganisme: null,
        num_secu: null,
        situation_familiale: null,
        moyen_contact: {
          libelle: null,
          lieu_dit_ou_bp: null,
          complement_adresse: null,
          indicatif_tel: null,
          tel: null,
          code_postal: null,
          ville_id: null,
          email: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['getEtude']), 
    canNext: function () {
      return _.find(this.resumeLocal[0].produitsFiltred, function (p) {
        return p.tarif.preconise
      })
    }
  },
  watch: {
    Tiers: {
      handler: function (val, oldVal) {
        this.mapProspectLocal(val)
      },
      immediate: true,
      deep: true
    },
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.resumeLocal = this.getItemsRisque.ItemsRisque
        this.checkerExplicationOptions = { isOption1: false, isOption2: false }
        this.hasProduct = false
        if (this.resumeLocal.length > 0) {
            if(this.resumeLocal[0].produitsFiltred.length > 0) {
                this.checkPret(this.resumeLocal[0].produitsFiltred[0].tarif.detailTarifByPret)
            }
          this.resumeLocal.map(risque => {
            if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
              risque.produitsFiltred.map(rpf => {
                if (rpf.tarif.presente) this.hasProduct = true
              })
            }
          })
        }
      },
      immediate: true,
      deep: true
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque
    }
  },
  validations: {
    prospectLocal: {
      moyen_contact: {
        email: { required, email },
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required }
      }
    }
  },
  created() {
    this.getPaysList()
  },
  methods: {
    initBudget(tarifByPret, isAssure, key) {
        var total = tarifByPret.filter(item => item.is_assure == isAssure)
        .reduce((accu, objet) => {
          // console.log((!isNaN(Number(objet[key])) ? Number(objet[key]) : 0));
          return accu + (!isNaN(Number(objet[key])) ? Number(objet[key]) : 0)
        }, 0)
        return Number(total).toFixed(2)
    },
    checkPret(tarifByPret) {
        this.countPrets.principal  = tarifByPret.filter(item => item.pret == 'Principal').length
        this.countPrets.secondaire  = tarifByPret.filter(item => item.pret == 'Secondaire').length
    },
    getPaysList() {
      this.$http
        .get(`tiers/getPays`)
        .then(r => {
          let france = r.data.find(item => item.indicatif == '+33')
          r.data.sort(function (x, y) {
            return x == france ? -1 : y == france ? 1 : 0
          })
          this.indicatif = r.data

          if (!this.prospectLocal.moyen_contact.indicatif_tel) {
            this.prospectLocal.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'comparatif'
        }
      })
    },
    mapProspectLocal(tiers) {
      this.prospectLocal.id = tiers.id
      this.prospectLocal.civilite = tiers.civilite
      this.prospectLocal.nom = tiers.nom
      this.prospectLocal.prenom = tiers.prenom
      this.prospectLocal.date_naissance = tiers.date_naissance
      this.prospectLocal.numeroOrganisme = tiers.numeroOrganisme
      this.prospectLocal.type = tiers.type
      this.prospectLocal.situation_familiale = tiers.situation_familiale
      this.prospectLocal.num_secu = tiers.num_secu
      this.prospectLocal.moyen_contact.libelle = tiers.moyen_contact.libelle
      this.prospectLocal.moyen_contact.lieu_dit_ou_bp = tiers.moyen_contact.lieu_dit_ou_bp
      this.prospectLocal.moyen_contact.complement_adresse = tiers.moyen_contact.complement_adresse
      this.prospectLocal.moyen_contact.tel = tiers.moyen_contact.tel
      this.prospectLocal.moyen_contact.code_postal = tiers.moyen_contact.code_postal
      this.prospectLocal.moyen_contact.ville_id = tiers.moyen_contact.ville_id
      this.prospectLocal.moyen_contact.email = tiers.moyen_contact.email
      this.prospectLocal.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    choisirProduit(risque, tarification) {
      this.resumeLocal[risque].produitsFiltred.map((pr, val) => {
        if (pr.tarif.tarification_id == tarification) {
          pr.tarif.preconise = true
        } else pr.tarif.preconise = false
      })
    },
    selectProduit() {
      // this.loadingOn()
      let produisPreconise = []
      this.hasSelectedProduit = false
      this.hasCommentedProduit = false
      let validProduit = null
      this.showLoading = true
      validProduit = this.resumeLocal[this.indexRisque].produitsFiltred.find(pr => pr.tarif.preconise)
      if (validProduit) {
        if (validProduit.tarif.commentaire !== '') {
          if (this.resumeLocal[this.indexRisque].produitsFiltred.length > 0) {
            this.resumeLocal[this.indexRisque].produitsFiltred.map(rlp => {
              produisPreconise.push({
                preconise: rlp.tarif.preconise,
                tarification_id: rlp.tarif.tarification_id,
                commentaire: rlp.tarif.commentaire,
                taux_commission: rlp.tarif.taux_commission ? rlp.tarif.taux_commission : null
              })
            })
          }
          if (produisPreconise.length > 0) {
            this.$http
              .post(`prospect/checkIfHasEmailAndTel`, this.prospectLocal)
              .then(r => {
                if (r.data.data) {
                  let data = {
                    produisPreconise: produisPreconise,
                    devis_id: this.resumeLocal[this.indexRisque].devis.id,
                    risque: this.resumeLocal[this.indexRisque].risque.label,
                    objet: this.resumeLocal[this.indexRisque].objets
                  }

                  this.$http
                    .post(`portefeuilles/updateTarifPreconise`, data)
                    .then(response => {
                      this.$store.commit('setStepTarif', {
                        step: {
                          indexStep: 4,
                          active: 'courtier'
                        }
                      })
                    })
                    .catch(() => {})
                } else {
                  this.$refs['modal-display-prospect-data'].show()
                  this.showLoading = false
                }
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.showLoading = false
              })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Merci de saisir un commentaire',
              icon: 'EditIcon',
              variant: 'danger'
            }
          })
          this.hasCommentedProduit = true
          this.showLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Préconiser un produit',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
        this.hasSelectedProduit = true
        this.showLoading = false
      }
      // this.showLoading=false
    },
    filtreProduit(data) {
      return data ? data.filter(pr => pr.tarif.presente) : []
    },
    addEmailAndTel() {
      this.$v.$reset()
      this.$v.prospectLocal.$touch()
      if (this.$v.prospectLocal.$error) {
        return 0
      }
      this.$http
        .post(`portefeuilles/updateEmailAndTel`, this.prospectLocal)
        .then(res => {
          if (res.data.success) {
            this.$store.commit('setProspectEmailAndTel', { Tiers: this.prospectLocal })
            this.$refs['modal-display-prospect-data'].hide()
            this.selectProduit()
          }
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.resume > .card > .card-header {
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
</style>

<style>
.indicatif-chooser-tarif-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}

.indicatif-chooser-tarif-santesenior .vs__dropdown-toggle:focus {
  border-radius: 0.357rem 0 0 0.357rem;
}

.table-emprunteur th,.table-emprunteur td  {
    padding: 0.8rem 0.6rem!important;
}
.no-hover-bg {
  background-color: transparent !important;
}
</style>
