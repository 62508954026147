import { render, staticRenderFns } from "./SanteDeces.vue?vue&type=template&id=35b3ce20&scoped=true&"
import script from "./SanteDeces.vue?vue&type=script&lang=js&"
export * from "./SanteDeces.vue?vue&type=script&lang=js&"
import style0 from "./SanteDeces.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./SanteDeces.vue?vue&type=style&index=1&id=35b3ce20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b3ce20",
  null
  
)

export default component.exports