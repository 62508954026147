<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-modal id="modal-display-prospect-data" ref="modal-display-prospect-data" hide-footer centered size="lg" title="Informations Complémentaires" no-close-on-backdrop>
      <b-card-body style="padding: 0 1.5rem 1.5rem">
        <b-form class="px-2" @submit.prevent="handleSubmitCheckData(onSubmit)" @reset.prevent="resetForm">
          <b-alert variant="warning" show class="mb-2">
            <div class="alert-body">
              <span> Merci de renseigner les informations de contact de votre [prospect/client] </span>
            </div>
          </b-alert>
          <b-form-group label="Email*" label-for="email">
            <b-form-input id="email" v-model="$v.prospectLocal.moyen_contact.email.$model" trim placeholder="Email" />
            <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.email.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group label="Téléphone Portable*" label-for="tel">
            <b-form-input id="nom_document" v-model="$v.prospectLocal.moyen_contact.tel.$model" trim placeholder="Téléphone Portable" v-mask="'## ## ## ## ##'" />
            <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.tel.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-form-group> -->

          <b-form-group label="Numéro de téléphone " label-for="phone">
            <b-row>
              <b-col sm="3" class="pr-0">
                <v-select v-model="$v.prospectLocal.moyen_contact.indicatif_tel.$model" :close-on-select="true" :clearable="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif" :reduce="indicatif => indicatif.indicatif" input-id="indicatif" label="indicatif" class="indicatif-chooser-tarif-santesenior w-100">
                  <template #option="{ indicatif, code }">
                    <span> {{ `${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">Aucune indicatif disponible.</div>
                </v-select>
              </b-col>
              <b-col sm="9" class="pl-0">
                <cleave id="phone" v-model="$v.prospectLocal.moyen_contact.tel.$model" :options="options.phone" :raw="false" class="form-control" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none" placeholder="ex: 06 00 00 00 00" />
              </b-col>
              <b-col sm="12">
                <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.tel.$error"> Le numéro de téléphone est obligatoire </b-form-invalid-feedback>
              </b-col>
              <b-col sm="12">
                <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.indicatif_tel.$error"> L'indicatif téléphonique est obligatoire </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="12">
              <div class="demo-inline-spacing">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="addEmailAndTel"> Enregistrer </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-modal>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 4 : préconisation d'une formule</h2>
        <h6>Préconisez une formule parmi les propositions (commentaire obligatoire)</h6>
      </b-col>
    </b-row>

    <b-row class="pricing-card" v-for="(rl, index) in resumeLocal" :key="index + 'content'">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row>
          <b-col md="12" lg="12" xl="4" class="resume d-flex align-items-stretch" v-for="(pr, val) in filtreProduit(rl.produitsFiltred)" :key="val + 'produits'">
            <b-card align="center" :header="pr.produit.produit_nom" class="text-center">
              <div class="d-flex flex-column bd-highlight mb-3">
                <div class="pt-1 bd-highlight">
                  <h5>{{ pr.tarif.formule_commercial }}</h5>
                </div>
                <div class="py-0 bd-highlight" style="height: 26px;">
                  <span class="d-block" style="font-size: 10px"> {{ pr.tarif.formule_descriptif }} </span>
                </div>
                <div class="pt-1 bd-highlight">
                  <b-badge :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.produit.produit_garanties[0], pr.tarif.codeFormule))" class="mb-1">
                    {{ getGtNvByNiveau(pr.produit.produit_garanties[0], pr.tarif.codeFormule) }}
                  </b-badge>
                </div>
                <div class="pt-1 bd-highlight">
                  <div class="annual-plan">
                    <div class="plan-price">
                      <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem"> {{ Number(pr.tarif.tarif).toFixed(2) }} </span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ rl.devis.fractionnement.toLowerCase() }}</sub>
                    </div>
                  </div>
                </div>
                <div class="pt-1 bd-highlight">
                <b-row style="font-size: 12px;" class="text-left px-1">
                  <b-col sm="12" class="border-bottom-primary border-top-primary border-2 py-1">
                    <b-row class="text-primary">
                      <b-col sm="7" class="p-0">Garanties complémentaires et franchises</b-col>
                      <b-col sm="3" class="p-0">Tarif mensuel TTC</b-col>
                      <b-col sm="2" class="p-0">Sélection</b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" class="pt-1" v-for="(pg, i) in filterListTarifOptions(pr.tarif)" :key="i + 'libellePGParent'">
                    <b-row >
                      <b-col sm="12" class="p-0">{{ pg.libellePG }}</b-col>
                      <b-col sm="12" class="p-0" v-for="(detail, j) in $_.orderBy(pg.details, ['order'], ['asc'])" :key="j + 'detailPGChild'">
                        <b-row class="ml-1">
                          <b-col sm="7" class="p-0">{{ detail.libelle_commercial }}</b-col>
                          <b-col sm="3" class="p-0">{{ Number(detail.tarif.mensuelle).toFixed(2) }}</b-col>
                          <b-col sm="2" class="p-0"> 
                            <feather-icon icon="CheckIcon" class="mr-50 text-success" v-if="detail.isSelected"/>
                            <feather-icon icon="MinusIcon" class="mr-50 text-secondary" v-else/>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                </div>
              </div>
              
              <template #footer><!-- Exigence -->
                <b-form-textarea placeholder="Commentaires..." v-model="pr.tarif.commentaire" rows="3" maxlength="255"> </b-form-textarea>
                <!-- buttons -->
                <b-button block class="mt-2" @click="choisirProduit(index, pr.tarif.tarification_id)" :variant="pr.tarif.preconise ? 'primary' : 'outline-primary'">
                  <span v-if="pr.tarif.preconise">Préconisée</span>
                  <span v-else>Préconiser</span>
                </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-2">
        <b-alert variant="warning" show class="mb-2 mx-2">
          <div class="alert-body">
            <span> Commentaires : Il s’agit des raisons pour lesquelles cette formule a été recommandée. </span>
            <br />
            <span> La motivation doit être claire, compréhensible et ne pas renvoyer de façon elliptique aux Conditions Générales ou à une documentation non synthétique. La motivation doit consister à justifier la cohérence du produit avec les exigences de votre client. Elle sera reprise dans la FIC. </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

        <app-collapse
          id="collapse-besoin"
          type="margin" 
          class="mb-3"
        >

    <app-collapse-item ref="tab-collapse-item-0" :is-visible="false" title="<h4 class='title-custom-wizard'><u>FMA - Détails garanties/franchises</u></h4>">
    <b-row>
        <b-col cols="12" class="mb-2" style="text-align: justify;">
            <p class="px-1">
              Garantie des équipements du conducteur 
            <ul>
                <li><strong>Equip 300€</strong> couvre les équipements moto (blouson, pantalon, bottes) à concurrence de 300 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros. </li>
                <li><strong>Equip 750€</strong> couvre les équipements moto (blouson, pantalon, bottes) à concurrence de 750 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros. </li>
                <li><strong>Equip 1500€</strong> couvre les équipements moto (blouson, pantalon, bottes) sont couverts à concurrence de 1500 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros</li>
            
              </ul>
            </p>

            <p class="px-1">
                Garantie dommages corporels du conducteur
            <ul>
                <li><strong>Protection du Pilote Essentielle</strong> indemnise le conducteur en cas d’atteinte permanente à l’intégralité
                    physique et psychique partielle (capital de 250 000 euros multiplié par le taux d’AIPP défini par le
                    médecin expert) et en cas de décès (20 000 euros au conjoint ou concubin, 7 500 euros par enfant à
                    charge et remboursement des frais d’obsèques à hauteur de 4 000 euros, le total de ces indemnités de
                    dépassera pas 250 000 euros). Le capital versé à l’assuré est doublé en cas de port du gilet airbag
                    (jusqu'à 500 000 euros).</li>
                <li><strong>Protection du Pilote Etendue</strong> indemnise le conducteur en cas d’atteinte permanente à l’intégralité
                    physique et psychique totale (capital de 500 000 euros), d’atteinte permanente à l’intégralité physique
                    et psychique partielle (capital de 500 000 euros multiplié par le taux d’AIPP défini par le médecin
                    expert) et en cas de décès (30 000 euros au conjoint ou concubin, 10 000 euros par enfant à charge et
                    remboursement des frais d’obsèques à hauteur de 4 000 euros, le total de ces indemnités de dépassera pas
                    500 000 euros). Le capital versé à l’assuré est doublé en cas de port du gilet airbag (jusqu'à 1 000 000
                    euros).</li>
            </ul>
            </p>
            
            <p class="px-1">
                Garantie du contenu du Top Case
            <ul>
                <li><strong>Contenu du Top Case 250 </strong>  garantie le vol des équipements du motard contenus dans le Top Case à hauteur de
                    250€ (par sinistre et par an) suite à l’effraction du Top Case ou suite au vol de la moto.</li>
                <li><strong>Contenu du Top Case 500+</strong> garantie le vol des équipements du motard ainsi que le vol des clés et des
                    papiers contenus dans le Top Case, à hauteur de 500€ (par sinistre et par an), suite à l’effraction du
                    Top Case ou suite au vol de la moto.</li>
            </ul>
            </p>

            <p class="px-1">
              Garantie des accessoires du véhicule (hors série)
            <ul>
                <li><strong>Access 1 550 €</strong> couvre, en cas de sinistre, les accessoires hors série du véhicules à concurrence de 1 500 euros. Avec application d’une franchise égale à 10% du montant des dommages avec un minimum de 76 euros. </li>
                <li><strong>Access 3 000 €</strong> couvre, en cas de sinistre, les accessoires hors série du véhicules à concurrence de 3 000 euros. Avec application d’une franchise égale à 10% du montant des dommages avec un minimum de 76 euros.</li>
            </ul>
            </p>
            <p class="px-1">
    Garantie valeur d'acquisition du véhicule
<ul>
<li><strong> Valeur d'acquisition 12 mois</strong> indemnise le véhicule sur sa valeur d’acquisition. </li>
</ul>
</p>
            <p class="px-1">
                Franchise en cas de vol ou incendie (par sinistre)
            <ul>
                <li>Avec l’option <strong> Franchise de base (ou partielle)</strong> en cas de vol ou d’incendie, la franchise retenue et à la charge
                    du souscripteur, est réduite au montant indiqué (sous réserve des conditions d’acceptation pour un rachat de franchise partiel).</li>
                <li>Avec l’option <strong> Franchise à 0 €</strong> en cas de vol ou d’incendie, aucune franchise n'est retenue (sous réserve
                    des conditions d’acceptation).</li>
            </ul>
            </p>

            <p class="px-1">
                Franchise en cas de dommages à votre véhicule (par sinistre)
            <ul>
                <li>Avec l’option <strong> Franchise de base (ou partielle)</strong> Dommages, en cas de sinistre totalement ou partiellement
                    responsable, une franchise réduite au montant indiqué est retenue et à charge du souscripteur (sous réserve des conditions d’acceptation pour un rachat de franchise partiel).</li>
                <li>Avec l’option <strong> Franchise à 0 €</strong> Dommages, en cas de sinistre totalement ou partiellement responsable,
                    aucune franchise n'est retenue (sous réserve des conditions d’acceptation).</li>
            </ul>
            </p>
        </b-col>
    </b-row>
  </app-collapse-item>
</app-collapse>

   
    <b-row>
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
        <b-button v-if="canNext" variant="primary" class="float-right" size="lg" @click="selectProduit"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BOverlay, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, VBTooltip, BForm, BCardBody, BAlert, BBadge } from 'bootstrap-vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Cleave from 'vue-cleave-component'
//import { codeIcon } from './code'

export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BForm,
    BCardBody,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BBadge
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      checkerExplicationOptions: { isOption1: false, isOption2: false },
      resumeLocal: [],
      indexLocal: 0,
      indexRisque: 0,
      hasProduct: false,
      hasCommentedProduit: false,
      hasSelectedProduit: false,
      showLoading: false,
      indicatif: [],
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true
        }
      },
      prospectLocal: {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        date_naissance: null,
        type: null,
        numeroOrganisme: null,
        num_secu: null,
        situation_familiale: null,
        moyen_contact: {
          libelle: null,
          lieu_dit_ou_bp: null,
          complement_adresse: null,
          indicatif_tel: null,
          tel: null,
          code_postal: null,
          ville_id: null,
          email: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['getEtude']),
    canNext: function () {
      return _.find(this.resumeLocal[0].produitsFiltred, function (p) {
        return p.tarif.preconise
      })
    }
  },
  watch: {
    Tiers: {
      handler: function (val, oldVal) {
        this.mapProspectLocal(val)
      },
      immediate: true,
      deep: true
    },
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.resumeLocal = this.getItemsRisque.ItemsRisque
        this.checkerExplicationOptions = { isOption1: false, isOption2: false }
        this.hasProduct = false
        if (this.resumeLocal.length > 0) {
          this.resumeLocal.map(risque => {
            if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
              risque.produitsFiltred.map(rpf => {
                if (rpf.tarif.presente) this.hasProduct = true
              })
            }
          })

          // let choosedSurComp = _.find(this.resumeLocal[0].produitsFiltred, (value, key) => value.hasSurComp && value.tarif.presente)
          // this.checkerExplicationOptions = []
          // choosedSurComp.garanties.map(gn => {
          //   let result = _.find(gn.garantieniveaux, function (gnr) {
          //     if (gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui') {
          //       let index = this.checkerExplicationOptions.findIndex(object => object === el.id)
          //       if (index === -1) {
          //         this.checkerExplicationOptions.push(el.id)
          //       }
          //     }
          //   })
          // })
        }
      },
      immediate: true,
      deep: true
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque
    }
  },
  validations: {
    prospectLocal: {
      moyen_contact: {
        email: { required, email },
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required }
      }
    }
  },
  created() {
    this.getPaysList()
  },
  methods: {
    filterListTarifOptions(tarif) {
      var listOptionsFinal = []
      tarif.produitsgaranties.forEach(pg => {
        var libellePG = pg.libelle_comm
        var orderPG = pg.order
        tarif.optionsCompatibles.forEach(oc => {  
          var produitGarantieNiveau = pg.produitgarantiesniveaux.find(item => item.libelle == Object.keys(oc)[0])
          if (produitGarantieNiveau ) {
            
            var isSelected = (pg.produitGarantieNiveauID.length > 0 && produitGarantieNiveau.id == pg.produitGarantieNiveauID[0]) ? true : false
            
            listOptionsFinal.push({
              libellePG,
              orderPG,
              libelle_commercial: produitGarantieNiveau.libelle_commercial,
              order: produitGarantieNiveau.order,
              libelle : produitGarantieNiveau.libelle,
              tarif: oc[Object.keys(oc)[0]].tarif,
              isSelected
            })
          }
        });
      });
      let result = []
      if (listOptionsFinal.length > 0) {
        result = _.chain(listOptionsFinal)
                .groupBy('libellePG')
                .map((value, key) => ({
                  orderPG: value[0].orderPG,
                  libellePG: key,
                  produitGarantieID: value[0].produitGarantieID,
                  tarificationProduitGtNv: value[0].tarificationProduitGtNv,
                  details: value
                }))
                .value()
        // result = _.chain(listOptionsFinal).groupBy("libellePG").map((value, key) => ({ libellePG: key, details: value })).value()
      }
      return _.orderBy(result, ['orderPG'],['asc']);
    },
    getPaysList() {
      this.$http
        .get(`tiers/getPays`)
        .then(r => {
          let france = r.data.find(item => item.indicatif == '+33')
          r.data.sort(function (x, y) {
            return x == france ? -1 : y == france ? 1 : 0
          })
          this.indicatif = r.data

          if (!this.prospectLocal.moyen_contact.indicatif_tel) {
            this.prospectLocal.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    formatSurComplementaire(niveauLabel) {
      let option = ''
      niveauLabel.forEach(el => {
        // Ajouter les IDs dans Array pour créer une condition pour afficher les explications des étoiles du garanties
        // id 46 => renfort retraité +
        // id 47 => essentiel pharma
        if (el.id == 46) {
          option += 'avec ' + el.produit_garantie.libelle.toLowerCase()

          if (!this.checkerExplicationOptions.isOption1) {
            this.checkerExplicationOptions.isOption1 = true
          }
        } else if (el.id == 47) {
          if (option == '') {
            option = 'avec option ' + el.produit_garantie.libelle.toLowerCase()
          } else {
            option += ' et option ' + el.produit_garantie.libelle.toLowerCase()
          }

          if (!this.checkerExplicationOptions.isOption2) {
            this.checkerExplicationOptions.isOption2 = true
          }
        }
      })

      return option
    },
    ifHasSurComp(produit) {
      produit.hasSurComp = false
      produit.niveauLabel = []
      if (produit.garanties && produit.garanties.length > 0) {
        produit.garanties.map(gn => {
          let result = _.find(gn.garantieniveaux, function (gnr) {
            return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui'
          })

          if (result) {
            produit.niveauLabel.push(gn)
            produit.hasSurComp = true
          }
        })
      }
      return produit
    },
    ifChooseSurComp() {
      if (this.resumeLocal[0] && this.resumeLocal[0].produitsFiltred && this.resumeLocal[0].produitsFiltred.length > 0) {
        let choosedSurComp = _.find(this.resumeLocal[0].produitsFiltred, (value, key) => value.hasSurComp && value.tarif.presente)

        // choosedSurComp.garanties.map(gn => {
        //   let result = _.find(gn.garantieniveaux, function (gnr) {
        //     if (gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui') {
        //       let index = this.checkerExplicationOptions.findIndex(object => object === el.id)
        //       if (index === -1) {
        //         this.checkerExplicationOptions.push(el.id)
        //       }
        //     }
        //   })
        // })

        return choosedSurComp ? true : false
      } else {
        return false
      }
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'comparatif'
        }
      })
    },
    mapProspectLocal(tiers) {
      this.prospectLocal.id = tiers.id
      this.prospectLocal.civilite = tiers.civilite
      this.prospectLocal.nom = tiers.nom
      this.prospectLocal.prenom = tiers.prenom
      this.prospectLocal.date_naissance = tiers.date_naissance
      this.prospectLocal.numeroOrganisme = tiers.numeroOrganisme
      this.prospectLocal.type = tiers.type
      this.prospectLocal.situation_familiale = tiers.situation_familiale
      this.prospectLocal.num_secu = tiers.num_secu
      this.prospectLocal.moyen_contact.libelle = tiers.moyen_contact.libelle
      this.prospectLocal.moyen_contact.lieu_dit_ou_bp = tiers.moyen_contact.lieu_dit_ou_bp
      this.prospectLocal.moyen_contact.complement_adresse = tiers.moyen_contact.complement_adresse
      this.prospectLocal.moyen_contact.tel = tiers.moyen_contact.tel
      this.prospectLocal.moyen_contact.code_postal = tiers.moyen_contact.code_postal
      this.prospectLocal.moyen_contact.ville_id = tiers.moyen_contact.ville_id
      this.prospectLocal.moyen_contact.email = tiers.moyen_contact.email
      this.prospectLocal.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    getExigence(egn, egnArray) {
      let tmpOption = _.find(egnArray, (value, key) => value.id == egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    comparerExig(ex1, ex2) {
      return ex1 == ex2
    },
    updateIndexDevis(value) {
      this.indexRisque = value
      this.$store.commit('setSelectedRisque', {
        selectedRisque: value
      })
    },
    choisirProduit(risque, tarification) {
      this.resumeLocal[risque].produitsFiltred.map((pr, val) => {
        if (pr.tarif.tarification_id == tarification) {
          pr.tarif.preconise = true
        } else pr.tarif.preconise = false
      })
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      if (this.resumeLocal[risque].produitsFiltred[produit]) {
        let result = this.resumeLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
          }
        } else {
          return ''
        }
      }
    },
    getGtNvByNiveau(produit_garantie, niveau) {
      let tmpGNPOption = _.find(produit_garantie.garantiesniveaux, (value, key) => value.pivot.libelle == niveau)
      return tmpGNPOption ? tmpGNPOption.garantie_niveau_libel : ''
    },
    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      if (this.resumeLocal[risque].produitsFiltred[produit]) {
        let result = this.resumeLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? (resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '') : ''
          }
        } else {
          return ''
        }
      }
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Intermédiaire':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    selectProduit() {
      // this.loadingOn()
      let produisPreconise = []
      this.hasSelectedProduit = false
      this.hasCommentedProduit = false
      let validProduit = null
      this.showLoading = true
      validProduit = this.resumeLocal[this.indexRisque].produitsFiltred.find(pr => pr.tarif.preconise)
      if (validProduit) {
        if (validProduit.tarif.commentaire !== '') {
          if (this.resumeLocal[this.indexRisque].produitsFiltred.length > 0) {
            this.resumeLocal[this.indexRisque].produitsFiltred.map(rlp => {
              produisPreconise.push({
                preconise: rlp.tarif.preconise,
                tarification_id: rlp.tarif.tarification_id,
                commentaire: rlp.tarif.commentaire,
                taux_commission: rlp.tarif.taux_commission ? rlp.tarif.taux_commission : null
              })
            })
          }
          if (produisPreconise.length > 0) {
            this.$http
              .post(`prospect/checkIfHasEmailAndTel`, this.prospectLocal)
              .then(r => {
                if (r.data.data) {
                  let data = {
                    produisPreconise: produisPreconise,
                    devis_id: this.resumeLocal[this.indexRisque].devis.id,
                    risque: this.resumeLocal[this.indexRisque].risque.label,
                    objet: this.resumeLocal[this.indexRisque].objets
                  }

                  this.$http
                    .post(`portefeuilles/updateTarifPreconise`, data)
                    .then(response => {
                      this.$store.commit('setStepTarif', {
                        step: {
                          indexStep: 4,
                          active: 'courtier'
                        }
                      })
                    })
                    .catch(() => {})
                } else {
                  this.$refs['modal-display-prospect-data'].show()
                  // this.showLoading = false
                }
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.showLoading = false
              })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Merci de saisir un commentaire',
              icon: 'EditIcon',
              variant: 'danger'
            }
          })
          this.hasCommentedProduit = true
          this.showLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Préconiser un produit',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
        this.hasSelectedProduit = true
        this.showLoading = false
      }
      // this.showLoading=false
    },
    filtreProduit(data) {
      return data ? data.filter(pr => pr.tarif.presente) : []
    },

    getOptionGarantie(grn, grnArray, label, options) {
      if (!_.isEmpty(options)) {
        let tmpOption = _.find(options, (v, key) => key == label)
        if (tmpOption) {
          if (grn) {
            let found = grnArray.find(gn => gn.id == grn && gn.garantie_niveau_libel == 'Oui')
            return found ? true : false
          } else {
            return false
          }
        } else return false
      } else {
        return false
      }
    },
    formatText(str) {
      var map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ'
      }
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern)
      }
      return str.split(' ').join('').toUpperCase()
    },
    async updateDevisSouscriptionAvancement() {
      let data = {
        id: this.resumeLocal[this.indexRisque].devis.id,
        devis_etape_avancement: 'courtier'
      }
      await this.$http
        .post(`devis/updateDevisSouscriptionState`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    async updateDevisDateEnvoi() {
      let data = {
        id: this.resumeLocal[this.indexRisque].devis.id,
        date_envoi: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }
      await this.$http
        .post(`devis/updateDevisDateEnvoi`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    addEmailAndTel() {
      this.$v.$reset()
      this.$v.prospectLocal.$touch()
      if (this.$v.prospectLocal.$error) {
        return 0
      }
      this.$http
        .post(`portefeuilles/updateEmailAndTel`, this.prospectLocal)
        .then(res => {
          if (res.data.success) {
            this.$store.commit('setProspectEmailAndTel', { Tiers: this.prospectLocal })
            this.$refs['modal-display-prospect-data'].hide()
            this.selectProduit()
          }
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.resume > .card > .card-header {
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
</style>

<style>
.indicatif-chooser-tarif-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}

.indicatif-chooser-tarif-santesenior .vs__dropdown-toggle:focus {
  border-radius: 0.357rem 0 0 0.357rem;
}
</style>
