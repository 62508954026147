<template>
    <div>
      <b-overlay :show="showLoading" no-wrap />
      <!-- Header -->
      <b-row class="p-1">
        <b-col>
        <b-alert
                    v-if="errorsWsTarification.length>0"
                    variant="danger"
                    show
                    class="mb-0"
                  >
                  
                    <div class="alert-body">
                      <div v-for="(err,key) in errorsWsTarification" :key="key">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                      {{err}}
                      </div>
                    </div>
        </b-alert>
      </b-col> 
    </b-row>

      <b-row>
        <b-col cols="12">
          <h2 class="mb-0 text-uppercase font-weight-bolder">étape 5 : information du courtier</h2>
          <h6>Vérifiez et complétez les infos relatives à votre activité</h6>
        </b-col>
      </b-row>
  
      <!-- Body -->
      <validation-observer ref="courtierStepComposeRules">
        <div class="m-2">
          <!-- Body - Renumérotion -->
          <b-row>
            <b-col cols="12">
              <h4 class="title-custom-wizard text-primary"><u>Rémunération</u></h4>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Pour la distribution de ce contrat, vous serez rémunéré sur la base :">
                <div class="demo-inline-spacing">
                  <b-form-checkbox v-model="composeData.devis.selectedCommissionRenumeration" :value="1" plain disabled> D'une commission versée par l'organisme assureur </b-form-checkbox>
                  <b-form-checkbox v-model="composeData.devis.selectedHonorairesRenumeration" :value="1" plain> D’honoraires </b-form-checkbox>
                  <b-form-checkbox v-model="composeData.devis.selectedAutreTypeRenumeration" :value="1" plain> De tout autre type de rémunération </b-form-checkbox>
                </div>
                <!-- required_if:is_anassociation_autoregulation,true -->
                <b-row v-if="composeData.devis.selectedHonorairesRenumeration">
                  <b-col md="12" xl="6" v-show="!composeData.devis.methodeCalcul">
                    <b-form-group label="Montant des honoraires" label-for="montant_honoraire">
                      <validation-provider #default="{ errors }" name="montant des honoraires" :rules="checkRequired() == 'both' || checkRequired() == 'nbrHonoraire' ? 'required' : ''">
                        <cleave id="budget" v-model.trim="composeData.devis.nbrHonoraire" :options="options.number" :raw="false" class="form-control" placeholder="Montant des honoraires" :class="errors.length > 0 ? 'is-invalid' : ''" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" xl="6" v-show="!composeData.devis.nbrHonoraire">
                    <b-form-group label="A défaut de pouvoir donner le montant, préciser la méthode de calcul" label-for="methode_calcul">
                      <validation-provider #default="{ errors }" name="méthode de calcul" :rules="checkRequired() == 'both' || checkRequired() == 'methodeCalcul' ? 'required' : ''">
                        <b-form-input id="methode_calcul" v-model="composeData.devis.methodeCalcul" trim placeholder="Méthode de calcul" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
  
          <!-- Body - Lien avec des entreprises d'assurance -->
          <b-row>
            <b-col cols="12">
              <h4 class="title-custom-wizard text-primary"><u>Lien avec des entreprises d'assurance</u></h4>
            </b-col>
            <b-col cols="12">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <p>Les information suivantes sont nécessaires à a réalisation de la fiche d'Information et de conseil.</p>
                  <p>Les entreprises d'assurance visées dans les questions sont les <u>institutions de prévoyance, les mutuelles ou les compagnies d'assurance.</u></p>
                  <p>Les liens avec un courtier grossiste ne doivent pas être mentionnés.</p>
                </div>
              </b-alert>
  
              <!-- Avez-vous un lien d'exclusivité avec une entreprise d'assurance ? -->
              <div>
                <b-form-group label="Avez-vous un lien d'exclusivité avec une entreprise d'assurance ?*">
                  <validation-provider #default="{ errors }" name="lien d'exclusivité" rules="required">
                    <b-form-radio-group v-model="composeData.courtier.lienExclusivite" :options="options.OuiNon" class="demo-inline-spacing" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <!-- Nom de l'entreprise d'assurance * : -->
                <b-form-group :label="composeData.courtier.lienExclusivite == 0 ? 'Quelles sont les entreprises d\'assurance dont vous distribuez le produit ?*' : ''">
                  <b-row class="w-100 align-items-center my-2" v-for="(entreprise, index) in composeData.courtier.EPLienExclusivite" :key="index">
                    <b-col>
                      <b>Nom de l'entreprise d'assurance * : </b>
                    </b-col>
                    <b-col>
                      <validation-provider #default="{ errors }" name="nom de l'entreprise d'assurance" rules="required">
                        <b-form-input v-model="entreprise.nom" placeholder="Nom de l'entreprise d'assurance" :state="errors.length > 0 ? false : null" />
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <b-button v-if="composeData.courtier.EPLienExclusivite.length > 1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle" @click="removeEntrepriseAssurance(entreprise.id, index, 'lienExclusivite')">
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-button v-if="composeData.courtier.lienExclusivite == 0" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="addEntrepriseAssurance('lienExclusivite')">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle text-uppercase">Ajouter une entreprise d'assurance</span>
                  </b-button>
                </b-form-group>
              </div>
  
              <!-- Entretenez-vous des liens financiers avec une entreprise d'assurance ? -->
              <div>
                <b-form-group label="Entretenez-vous des liens financiers avec une entreprise d'assurance ?*">
                  <validation-provider #default="{ errors }" name="lien financier" rules="required">
                    <b-form-radio-group v-model="composeData.courtier.lienFinancier" :options="options.OuiNon" class="demo-inline-spacing" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
  
                <b-form-group v-if="composeData.courtier.lienFinancier == 1">
                  <!-- In -->
                  <b-form-group label="Votre cabinet de courtage détient-il plus de 10% des droits de vote et/ou de capital d'une (ou plusieurs) entreprise d'assurance ?*">
                    <validation-provider #default="{ errors }" name="Votre cabinet de courtage détient-il plus de 10% des droits de vote et/ou de capital d'une (ou plusieurs) entreprise d'assurance" rules="required">
                      <b-form-radio-group v-model="composeData.courtier.actionnaireIn" :options="options.OuiNon" class="demo-inline-spacing" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
  
                  <b-form-group v-if="composeData.courtier.actionnaireIn == 1">
                    <b-row class="w-100 align-items-center my-2" v-for="(entreprise, index) in composeData.courtier.EPActionnaireIn" :key="index">
                      <b-col>
                        <b>Nom de l'entreprise d'assurance * : </b>
                      </b-col>
                      <b-col>
                        <validation-provider #default="{ errors }" name="nom de l'entreprise d'assurance" rules="required">
                          <b-form-input v-model="entreprise.nom" placeholder="Nom de l'entreprise d'assurance" :state="errors.length > 0 ? false : null" />
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <b-button v-if="composeData.courtier.EPActionnaireIn.length > 1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle" @click="removeEntrepriseAssurance(entreprise.id, index, 'actionnaireIn')">
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="addEntrepriseAssurance('actionnaireIn')">
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle text-uppercase">Ajouter une entreprise d'assurance</span>
                    </b-button>
                  </b-form-group>
  
                  <!-- Out -->
                  <b-form-group label="Une (ou plusieurs) entreprise d'assurance détient-elle plus de 10% des droits de vote et/ou de capital de votre cabinet de courtage ?*">
                    <validation-provider #default="{ errors }" name="Une (ou plusieurs) entreprise d'assurance détient-elle plus de 10% des droits de vote et/ou de capital de votre cabinet de courtage" rules="required">
                      <b-form-radio-group v-model="composeData.courtier.actionnaireOut" :options="options.OuiNon" class="demo-inline-spacing" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
  
                  <b-form-group v-if="composeData.courtier.actionnaireOut == 1">
                    <b-row class="w-100 align-items-center my-2" v-for="(entreprise, index) in composeData.courtier.EPActionnaireOut" :key="index">
                      <b-col>
                        <b>Nom de l'entreprise d'assurance * : </b>
                      </b-col>
                      <b-col>
                        <validation-provider #default="{ errors }" name="nom de l'entreprise d'assurance" rules="required">
                          <b-form-input v-model="entreprise.nom" placeholder="Nom de l'entreprise d'assurance" :state="errors.length > 0 ? false : null" />
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <b-button v-if="composeData.courtier.EPActionnaireOut.length > 1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle" @click="removeEntrepriseAssurance(entreprise.id, index, 'actionnaireOut')">
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="addEntrepriseAssurance('actionnaireOut')">
                      <feather-icon icon="PlusIcon" class="mr-50" />
                      <span class="align-middle text-uppercase">Ajouter une entreprise d'assurance</span>
                    </b-button>
                  </b-form-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
      <!-- Footer -->
      <b-row>
        <b-col lg="12">
          <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
          <b-button variant="primary" class="float-right" size="lg" @click="recordData()"> Suivant </b-button>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import { BRow, BCol, BOverlay, BButton, VBTooltip, BFormCheckbox, BAlert, BFormGroup, BFormRadioGroup, BFormInvalidFeedback, BFormInput, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { mapGetters } from 'vuex'
  import Ripple from 'vue-ripple-directive'
  import Cleave from 'vue-cleave-component'
  import moment from 'moment'
  
  export default {
    components: {
      // Validation
      ValidationProvider,
      ValidationObserver,
  
      //VBT
      BRow,
      BCol,
      BOverlay,
      BButton,
      BFormGroup,
      BFormRadioGroup,
      BFormCheckbox,
      BAlert,
      BFormInvalidFeedback,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      Cleave
    },
    data() {
      return {
        currentUser: JSON.parse(localStorage.getItem('userData')),
        showLoading: false,
        indexRisque: 0,
        resumeLocal: [],
        errorsWsTarification : [],
        hasProduct: false,
        EPLienExclusivite: [],
        composeData: {
          courtier: {
            EPActionnaireIn: [],
            EPActionnaireOut: [],
            EPLienExclusivite: [],
            actionnaireIn: null,
            actionnaireOut: null,
            id: null,
            lienExclusivite: null,
            lienFinancier: null
          },
          devis: {
            selectedCommissionRenumeration: 1,
            selectedAutreTypeRenumeration: 0,
            id: null,
            selectedHonorairesRenumeration: 0,
            nbrHonoraire: null,
            methodeCalcul: null
          }
        },
        options: {
          OuiNon: [
            { text: 'Oui', value: 1 },
            { text: 'Non', value: 0 }
          ],
  
          number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalMark: ',',
            delimiter: ' '
          }
        }
      }
    },
    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
    computed: {
      ...mapGetters(['Tiers']),
      ...mapGetters(['getItemsRisque']),
      ...mapGetters(['getSelectedRisque']),
      ...mapGetters(['getEtude'])
    },
    watch: {
      'composeData.devis.selectedHonorairesRenumeration': {
        handler(val) {
          if (!val) {
            this.composeData.devis.nbrHonoraire = null
            this.composeData.devis.methodeCalcul = null
          }
        }
      },
      'composeData.courtier.lienExclusivite': {
        handler(val) {
          if (val) {
            if (this.composeData.courtier.EPLienExclusivite.length > 1) {
              this.EPLienExclusivite = this.composeData.courtier.EPLienExclusivite
              this.composeData.courtier.EPLienExclusivite = []
              this.$refs.courtierStepComposeRules.reset()
            }
          } else {
            if (this.EPLienExclusivite.length > 0) {
              this.composeData.courtier.EPLienExclusivite = this.EPLienExclusivite
              this.EPLienExclusivite = []
            }
          }
  
          if (this.composeData.courtier.EPLienExclusivite.length <= 0) {
            this.addEntrepriseAssurance('lienExclusivite')
          }
        }
      },
      'composeData.courtier.lienFinancier': {
        handler(val) {
          if (!val) {
            this.composeData.courtier.actionnaireIn = 0
            this.composeData.courtier.actionnaireOut = 0
          }
        }
      },
      'composeData.courtier.actionnaireIn': {
        handler(val) {
          if (val == 0) {
            // this.composeData.courtier.EPActionnaireIn = this.composeData.courtier.EPActionnaireIn.filter(function (el) {
            //   return el.id != null
            // })
            if (this.composeData.courtier.EPActionnaireIn.length > 0) {
              this.deleteEntreprisePartenaireByCourtierAndType(this.composeData.courtier.id, 'actionnaireIn')
            }
          } else if (val == 1) {
            if (this.composeData.courtier.EPActionnaireIn.length <= 0) {
              this.addEntrepriseAssurance('actionnaireIn')
            }
          }
        }
      },
      'composeData.courtier.actionnaireOut': {
        handler(val) {
          if (val == 0) {
            // this.composeData.courtier.EPActionnaireOut = this.composeData.courtier.EPActionnaireOut.filter(function (el) {
            //   return el.id != null
            // })
  
            if (this.composeData.courtier.EPActionnaireOut.length > 0) {
              this.deleteEntreprisePartenaireByCourtierAndType(this.composeData.courtier.id, 'actionnaireOut')
            }
          } else if (val == 1) {
            if (this.composeData.courtier.EPActionnaireOut.length <= 0) {
              this.addEntrepriseAssurance('actionnaireOut')
            }
          }
        }
      },
      getEtude: {
        handler(val) {
          this.composeData.devis.id = val.itemsRisque.ItemsRisque[0].devis.id
        },
        immediate: true,
        deep: true
      },
      getItemsRisque: {
        handler(val, oldVal) {
          this.resumeLocal = this.getItemsRisque.ItemsRisque
  
          this.hasProduct = false
          if (this.resumeLocal.length > 0) {
            this.resumeLocal.map(risque => {
              if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
                risque.produitsFiltred.map(rpf => {
                  if (rpf.tarif.presente) this.hasProduct = true
                })
              }
            })
          }
        },
        immediate: true,
        deep: true
      },
      getSelectedRisque: {
        handler(val) {
          this.indexRisque = val
        }
      }
    },
    mounted() {
      this.fetchDataCourtierStepSouscription()
    },
    methods: {
      checkRequired() {
        if (this.composeData.devis.selectedHonorairesRenumeration) {
          if (!this.composeData.devis.nbrHonoraire && !this.composeData.devis.methodeCalcul) {
            return 'both'
          } else if (this.composeData.devis.nbrHonoraire && !this.composeData.devis.methodeCalcul) {
            return 'nbrHonoraire'
          } else if (!this.composeData.devis.nbrHonoraire && this.composeData.devis.methodeCalcul) {
            return 'methodeCalcul'
          }
        } else {
          return ''
        }
      },
      addEntrepriseAssurance(where) {
        if (where == 'lienExclusivite') {
          this.composeData.courtier.EPLienExclusivite.push({ id: null, nom: null, distribution: 1, actionnaireIn: null, actionnaireOut: null, courtierId: this.currentUser.courtier_user[0].courtier_id })
        } else if (where == 'actionnaireOut') {
          this.composeData.courtier.EPActionnaireOut.push({ id: null, nom: null, distribution: null, actionnaireIn: null, actionnaireOut: 1, courtierId: this.currentUser.courtier_user[0].courtier_id })
        } else if (where == 'actionnaireIn') {
          this.composeData.courtier.EPActionnaireIn.push({ id: null, nom: null, distribution: null, actionnaireIn: 1, actionnaireOut: null, courtierId: this.currentUser.courtier_user[0].courtier_id })
        }
        this.$refs.courtierStepComposeRules.reset()
      },
      removeEntrepriseAssurance(id, index, where) {
        // this.$swal({
        //   html: '<p style="font-size: 19px;font-weight: 500;">Êtes-vous sûr de vouloir supprimer cette entreprise ?</p>',
        //   icon: 'warning',
        //   showCancelButton: true,
        //   confirmButtonText: 'Oui',
        //   cancelButtonText: 'Non',
        //   customClass: {
        //     confirmButton: 'btn btn-primary',
        //     cancelButton: 'btn btn-outline-danger ml-1'
        //   },
        //   buttonsStyling: false
        // }).then(result => {
        //   if (result.value) {
  
        this.showLoading = true
        if (id) {
          this.$http
            .delete(`/entreprisePartenaire/deleteEntreprisePartenaire/${id}`)
            .then(res => {
              if (res.data.success) {
                if (where == 'lienExclusivite') {
                  this.composeData.courtier.EPLienExclusivite.splice(index, 1)
                } else if (where == 'actionnaireOut') {
                  this.composeData.courtier.EPActionnaireOut.splice(index, 1)
                } else if (where == 'actionnaireIn') {
                  this.composeData.courtier.EPActionnaireIn.splice(index, 1)
                }
                this.showLoading = false
              }
            })
            .catch(err => {
              console.error(err)
              this.showLoading = false
            })
        } else {
          if (where == 'lienExclusivite') {
            this.composeData.courtier.EPLienExclusivite.splice(index, 1)
          } else if (where == 'actionnaireOut') {
            this.composeData.courtier.EPActionnaireOut.splice(index, 1)
          } else if (where == 'actionnaireIn') {
            this.composeData.courtier.EPActionnaireIn.splice(index, 1)
          }
          this.showLoading = false
        }
        // }
        // })
      },
      deleteEntreprisePartenaireByCourtierAndType(courtierId, type) {
        let where = null
  
        this.showLoading = true
        if (type == 'lienExclusivite') {
          where = {
            distribution: 1,
            courtierId: courtierId
          }
        } else if (type == 'actionnaireIn') {
          where = {
            actionnaireIn: 1,
            courtierId: courtierId
          }
        } else if (type == 'actionnaireOut') {
          where = {
            actionnaireOut: 1,
            courtierId: courtierId
          }
        }
  
        if (where) {
          this.$http
            .post('/entreprisePartenaire/deleteEntreprisePartenaireByCourtierAndType', where)
            .then(res => {
              if (res.data.success) {
                if (type == 'lienExclusivite') {
                  this.EPLienExclusivite.forEach((element, index) => {
                    this.EPLienExclusivite.splice(index, this.EPLienExclusivite.length)
                  })
                  // this.addEntrepriseAssurance('lienExclusivite')
                } else if (type == 'actionnaireIn') {
                  this.composeData.courtier.EPActionnaireIn.forEach((element, index) => {
                    this.composeData.courtier.EPActionnaireIn.splice(index, this.composeData.courtier.EPActionnaireIn.length)
                  })
                } else if (type == 'actionnaireOut') {
                  this.composeData.courtier.EPActionnaireOut.forEach((element, index) => {
                    this.composeData.courtier.EPActionnaireOut.splice(index, this.composeData.courtier.EPActionnaireOut.length)
                  })
                }
  
                this.showLoading = false
              }
            })
            .catch(err => {
              console.error(err)
              this.showLoading = false
            })
        }
      },
      clearComposeDataCourtier() {
        this.composeData.courtier.EPActionnaireIn = []
        this.composeData.courtier.EPActionnaireOut = []
        this.composeData.courtier.EPLienExclusivite = []
        this.composeData.courtier.actionnaireIn = null
        this.composeData.courtier.actionnaireOut = null
        this.composeData.courtier.id = null
        this.composeData.courtier.lienExclusivite = null
        this.composeData.courtier.lienFinancier = null
      },
      fetchDataCourtierStepSouscription() {
        this.$http
          .get(`/courtier/fetchDataCourtierStepSouscription/${this.currentUser.courtier_user[0].courtier_id}`)
          .then(res => {
            this.composeData.courtier = res.data.data
          })
          .catch(err => {
            this.clearComposeDataCourtier()
            console.error(err)
          })
      },
      backStep() {
        this.errorsWsTarification = []
        this.$store.commit('setStepTarif', {
          step: {
            indexStep: 3,
            active: 'resume'
          }
        })
      },
      async updateDateValidite() {
        let produitPresente   = this.resumeLocal[0].produitsFiltred.filter(pr => pr.tarif.presente)
        let uniqueProducts    = _.uniqBy(produitPresente, 'produit.id');
        let dtValidite      = []
        let datesent = null
        uniqueProducts.forEach(element => {
          const assureurName = element.produit.assureur.nom_assureur;
          if (assureurName == 'ECA_ASSURANCES') {
              dtValidite.push(moment().add(31, 'days'))
          }
        })
        datesent = moment.min(dtValidite).format('DD/MM/YYYY')
        this.$http.put(`devis/updatedatValiditeDevis/${this.getEtude.itemsRisque.ItemsRisque[0].devis.id}`, { dtValidite: datesent })
      },
      // recod data and send mail
      recordData() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$refs.courtierStepComposeRules.validate().then(success => {
          if (success) {
            if (this.composeData.courtier.lienExclusivite == 1 && this.EPLienExclusivite.length > 1) {
              this.deleteEntreprisePartenaireByCourtierAndType(this.composeData.courtier.id, 'lienExclusivite')
            }
            this.showLoading = true
            this.$http
              .post('projet/information_courtier_souscuption', this.composeData)
              .then(res => {
                let doc = {
                  etude_id: this.getEtude.etudeID,
                  ipid: this.getEtude.ipid,
                  cg: this.getEtude.cg,
                  tg: this.getEtude.tg
                }
                this.$http
                  .post('projet/generate_project_protection_juridique', doc)
                  .then(async res => {
                    this.$store.commit('setEtudeConseil', {
                      // conseilpath: res.data.data.document.path+res.data.data.document.nom
                      conseilpath: res.data.data.document.id
                    })
                    let checker = await this.updateDevisState()
                    if(checker){
                      await this.updateDevisSouscriptionAvancement()
                      await this.updateDevisDateEnvoi()
                      await this.updateDateValidite()
                      await this.$store.commit('setStepTarif', { step: { indexStep: 5, active: 'etude' } })
                    }
  
                  })
              .catch(() => {
                this.showLoading = false
              })
  
              })
              .catch(() => {
                this.showLoading = false
              })
          }
        })
  
        this.showLoading = false
      },
      updateDevisState() {
        this.errorsWsTarification = [];
        const idDevis = this.getEtude.itemsRisque.ItemsRisque[0].devis.id
        let devisAvancement = 'SOUSCRIPTION'
        return new Promise((resolve, reject) => {
        this.$http.post('devis/updateDevisState', {id: idDevis, devis_avancement: devisAvancement})
          .then(() => {
            this.$http.post('portefeuilles/enregistrerDevis', { idDevis })
              .then(res1 => {
                if (res1.data.statut === 200) {
                  this.showLoading = false;
                  resolve(true);
                }else{
                  this.showLoading = false;
                    this.errorsWsTarification = res1.data.data;
                    devisAvancement = 'TARIFICATION';
                    this.$http.post('devis/updateDevisState', { id: idDevis, devis_avancement: devisAvancement })
                      .then(() => {
                        resolve(false); 
                      })
                      .catch(err => {
                        console.error(err);
                        reject(false); 
                      });
                }
              })
              .catch(err => {
                this.showLoading = false
                console.error(err)
                reject(false); 
              })
          })
          .catch(err => {
            console.error(err);
            reject(false); 
          })
        }); 
      },
      async updateDevisDateEnvoi() {
        let data = {
          id: this.resumeLocal[this.indexRisque].devis.id,
          date_envoi: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
        await this.$http
          .post('devis/updateDevisDateEnvoi', data)
          .then(r => {
            return true
          })
          .catch(err => {
            console.log(err)
          })
      },
      async updateDevisSouscriptionAvancement() {
        let data = {
          id: this.resumeLocal[this.indexRisque].devis.id,
          devis_etape_avancement: 'choixclient'
        }
        await this.$http
          .post('devis/updateDevisSouscriptionState', data)
          .then(r => {
            return true
          })
          .catch(err => {
            console.log(err)
          })
      },
     
      
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/pages/page-pricing.scss';
  .resume > .card > .card-header {
    background-color: #4d25bc !important;
    color: #fff;
    justify-content: center !important;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  </style>
  