<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row v-if="risqueLocal[0].produitsFiltred.length>0">
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 3 : Comparaison des offres
        </h2>
        <h6>
          Sélectionner les offres répondant le mieux aux besoins exprimés (2 minimum, 3 maximum)
        </h6>
      </b-col>
      <b-col lg="12">
        <b-button
            variant="primary"
            class="float-left my-2"
            size="lg"
            @click="backStep"
        >
          Précédent
        </b-button>
        <b-button
            v-if="canNext"
            variant="primary"
            class="float-right my-2"
            size="lg"
            @click="submitProduit"
        >
          Suivant
        </b-button>
      </b-col>
      <b-col lg="12">
        <b-alert variant="warning" show v-show="getAssureursRisque(risqueLocal[0].produits)" class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12">
        <b-alert variant="warning" show v-show="msgCodeBbi(risqueLocal[0].produits)" class="mt-1">
          <div class="alert-body">
            <p v-html="msgCodeBbi(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
      <transition name="fade">
        <b-col cols="12" v-if="showCompareTable">
          <div v-for="(rl, index) in this.risqueLocal" :key="index + 'content'" class="mb-5">
            <b-dropdown
                variant="outline-primary"
                class="mb-2 float-right"
                right
                split
                text="Trier par"
            >
              <b-dropdown-item @click="filtreUpDown(index)">Prix décroissant</b-dropdown-item>
              <b-dropdown-item @click="filtreDownUp(index)">Prix croissant</b-dropdown-item>
              <b-dropdown-item @click="filtreByTaux(index,'UpDown')">Adéquation</b-dropdown-item>
              <!-- <b-dropdown-item @click="filtreByTaux(index,'DownUp')">Taux croissant</b-dropdown-item> -->
            </b-dropdown>

            <b-table-simple bordered hover caption-top responsive v-for="(pr, val) in rl.produitsFiltred" :key="val + 'produits'" class="mb-4">
              <b-thead class="offere_thClass">
                <b-tr>
                  <b-th style="width:20%;" class="text-center">produit</b-th>
                  <b-th style="width:25%;" class="text-center">documents</b-th>
                  <b-th style="width:15%;" class="text-center">options</b-th>
                  <b-th style="width:25%;" class="text-center">adéquation au besoin</b-th>
                  <b-th style="width:15%;" class="text-center">prix</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="offere_tdClass">
                <b-td class="text-center">
                  <p class="box-custom-text">{{ pr.produit.produit_nom }}</p>
                  <template v-if="pr.produit.produit_ws_nom == 'TNS_SPHERIA'">
                    <b-img :src="require('@/assets/images/logo/assureur/Harmonie-assurance.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <template v-else-if="pr.produit.produit_ws_nom == 'ACTISAN' || pr.produit.produit_ws_nom == 'INSURACTIF_2024'">
                    <b-img :src="require('@/assets/images/logo/assureur/eca-assurances.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <template v-else>
                    <b-img :src="require('@/assets/images/logo/logo-back.svg')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <p class="box-custom-text">{{ pr.produit.assureur.nom_com_assureur }}</p>
                </b-td>
                <b-td>
                  <div v-for="(pda,compt) in  $_.orderBy(pr.produit.document_assureur, ['document.document_type.type'], ['asc'])" :key="compt+'pda'">
                    <span class="text-nowrap cursor-pointer" v-if="pda.document && pda.produit_garantie_id && hasSurComp(pr.tarif)"
                      @click="showFile(pda.document.id,pda.document.document_type.type)">
                      <feather-icon style="margin-bottom: 4px;" icon="FileIcon" size="14" color="#bf2626" />
                      <span class="text-nowrap">{{pda.document.document_type.type}}
                        Surcomplémentaire </span>
                    </span>
                    <span class="text-nowrap cursor-pointer" v-else-if="pda.document && !pda.produit_garantie_id"
                      @click="showFile(pda.document.id,pda.document.document_type.type)">
                      <feather-icon style="margin-bottom: 4px;" icon="FileIcon" size="14" color="#bf2626" />
                      <span class="text-nowrap">{{pda.document.document_type.type}}</span>
                    </span>
                  </div>
                </b-td>
                <b-td>
                  <b-row v-for="(gr,cpt) in pr.garanties" :key="cpt+'garantie'">
                    <b-col md="12" v-if="cpt!==0 && hasGarantieNiveau(getGarantieProduit(gr.id,pr.produit.produit_garanties),pr.tarif)">
                      <span>{{getGarantieProduitCommercial(gr.id,pr.produit.produit_garanties)}}</span>
                    </b-col>
                    <b-col md="12" v-if="cpt!==0">
                      <b-form-select size="sm" :value="gr.garantieNiveauID[0]"  @change="onChangeNiveau($event,index,val,cpt)" v-if="hasGarantieNiveau(getGarantieProduit(gr.id,pr.produit.produit_garanties),pr.tarif)" class="mb-2">
                        <b-form-select-option v-for="(gn, ind) in gr.garantieniveaux" :value="gn.id "  :key="ind + 'garantiesNiveaux'">
                          {{gn.garantie_niveau_libel }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row v-if="pr.produit.produitcommissions && pr.produit.produitcommissions.length>0">
                    <b-col md="12">
                      <span>Taux de commission :</span>
                    </b-col>
                    <b-col md="12">
                      <b-form-select size="sm"  v-model="pr.tarif.taux_commission" @change="onChangeTaux($event,index,val)">
                        <b-form-select-option  v-for="(prc, prcIndex) in pr.produit.produitcommissions"  :value="prc.commission_value "  :key="prcIndex + 'produitcommision'">
                          {{prc.commission_value }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td>
                  <b-row v-for="(eg,cptValue) in rl.exigences" :key="cptValue+'exigence'">
                    <b-col lg="6">
                      <span class="cursor-pointer" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="explicationExigenceLibel(eg.exigence_produits_details, pr.produit.id)"> {{eg.label}}</span>
                    </b-col>
                    <b-col lg="4">{{titleClass(getPtGtNvExigCorrespondant(index,val,eg.id,pr.tarif.formule))}}</b-col>
                    <!-- <b-col cols="2">{{eg.exigenceNiveauID.length>0 ? titleClass(getExigence(eg.exigenceNiveauID,eg.exigenceniveaux)):'' }}</b-col>
                    <b-col cols="2">{{titleClass(getPtGtNvExig(index,val,eg.id,pr.tarif.formule))}}</b-col> -->
                    <b-col class="m-0 p-0" lg="1" v-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule))>0">
                      <feather-icon
                          icon="PlusIcon"
                          size="16"
                          color="#26bf39"
                      />
                    </b-col>
                    <b-col  v-else-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule))<0" class="m-0 p-0" lg="1" >
                      <feather-icon
                          icon="MinusIcon"
                          size="16"
                          color="#bf2626"
                      />
                    </b-col>
                    <b-col  v-else class="m-0 p-0" lg="1">
                      <feather-icon
                          icon="CheckIcon"
                          size="16"
                          color="#26bf39"
                      />
                    </b-col>
                  </b-row>
                </b-td>
                <b-td>
                  <div class="text-center">
                    <div class="px-0">
                      <h4>{{ pr.tarif.formule_commercial}}</h4>
                      <span class="d-block" style="font-size:12px" v-if="ifHasSurComp(pr) ? pr.hasSurComp : false" >Option Surcomplémentaire **</span><br>
                      <b-badge :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.garanties[0],pr.tarif.formule))" class="mb-1">
                        {{getGtNvByNiveau(pr.garanties[0],pr.tarif.formule)}}
                      </b-badge>
                      <p class="card-text"> {{ pr.tarif.tarif.libelle =='Enfant' ? 'Adulte Enfant' : pr.tarif.tarif.libelle}}</p>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{ pr.tarif.tarif.tarif }}</span>
                          <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                        </div>
                      </div>
                      <p class="card-text mt-1" style="font-size:12px" v-if="pr.tarif.tarif.tarif_pmss">
                        Soit {{ pr.tarif.tarif.tarif_pmss}} % du PMSS <span v-show="hasPmssPrivilege">***</span>
                      </p>
                      <b-button
                          v-if="!pr.tarif.presente"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mt-2"
                          variant="white"
                          style="border:1px solid #7367f0"
                          size="sm"
                          @click.prevent="selectionnerProduit(index,val)"
                      >

                        Sélectionner
                      </b-button>
                      <b-button
                          v-else
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mt-2"
                          variant="primary"
                          size="sm"
                          @click.prevent="deselectionnerProduit(index,val)"
                      >

                        Déselectionner
                      </b-button>
                      <!-- <button v-if="!pr.tarif.presente" @click.prevent="selectionnerProduit(index,val)" type="button" class="btn mt-2  btn-sm" style="position: relative;">
                        :variant="pr.tarif.preconise ? 'primary' : 'outline-primary'"
                        Sélectionner
                      </button>
                      <button v-else @click.prevent="deselectionnerProduit(index,val)" type="button" class="btn mt-2 btn-primary btn-sm" style="position: relative;">
                        Déselectionner
                      </button> -->
                    </div>
                  </div>
                </b-td>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </transition>
    </b-row>

    <b-row v-else>
      <b-col lg="12">
        <b-alert variant="warning" show class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <!-- <section id="knowledge-base-search" v-else>
        <b-card class="knowledge-base-bg custom-tarif  mb-4" >
          <h3 class="text-primary">
            Nous sommes désolés. Aucune offre n'est disponible pour les informations saisies.
          </h3>
          <ul class="mt-2">
            <li>
              <span>Pour rappel :</span>
              <ul class="mt-1">
                <li class="font-weight-bolder">L'âge limite est de 65 ans.</li>
                <li class="font-weight-bolder">La date d'effet doit être sur l'année en cours.</li>
                <li class="font-weight-bolder">Certains départements ne sont pas éligibles.</li>
              </ul>
            </li>
          </ul>
        </b-card>
    </section> -->

    <b-row v-if="risqueLocal[0].produitsFiltred.length>0">
      <b-col cols="12" class="mb-2">
        <h6>
          * Adhérents aux dispositifs de pratique tarifaire maitrisée
        </h6>
      </b-col>
    </b-row>

    <b-row v-show="ifChooseSurComp()">
      <b-col cols="12" class="mb-2">
        <h6>
          ** L’option surcomplémentaire permet de bénéficier de garanties renforcées chez les praticiens non adhérents à l’OPTAM
        </h6>
      </b-col>
    </b-row>

    <b-row v-show="hasPmssPrivilege">
      <b-col cols="12" class="mb-2">
        <h6>
          *** Pour {{currentYear+1}}, le tarif est donné à titre indicatif sur la base d’une estimation du PMSS
        </h6>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-button
            variant="primary"
            class="float-left"
            size="lg"
            @click="backStep"
        >
          Précédent
        </b-button>
        <b-button
            v-if="canNext"
            variant="primary"
            class="float-right"
            size="lg"
            @click="submitProduit"
        >
          Suivant
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-display-document" ref="modal-display-document"
             :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name"
             ok-only ok-variant="outline-secondary" ok-title="Fermer"  @ok="clearDocumentDisplay"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";
import { selectRisqueAndProduct,updateProduitTarif } from './../../../../shared/utils/TarifCalcul'
import {
  BOverlay,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormSelectOption,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BModal,
  VBTooltip,
  BBadge,
  BImg
} from "bootstrap-vue";
import { required, email } from "@validations";
import Ripple from 'vue-ripple-directive'
import moment from  'moment'
//import { codeIcon } from './code'

export default {
  components: {
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BFormSelectOption,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    BBadge
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      risqueLocal: [],
      produitGarantieNiveau:[],
      tarif:Math.floor(Math.random() * 100) + 60,
      loading:false,
      indexLocal:0,
      test:null,
      indexRisque:0,
      hasProduct:false,
      showCompareTable:true,
      showLoading:false,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      currentYear:moment(new Date(), "DD/MM/YYYY").year()
    };
  },
  computed: {
    ...mapGetters(["getItemsRisque"]),
    ...mapGetters(["getSelectedRisque"]),
    ...mapGetters(["Tiers"]),
    canNext :function(){
      let nbrPresente=_.filter(this.risqueLocal[0].produitsFiltred, function(p) {
        return p.tarif.presente;
      });
      if(nbrPresente.length>1){
        return true
      }

      return false

      // return _.find(this.risqueLocal[0].produitsFiltred, function(p) { return p.tarif.presente; });
    },
    hasPmssPrivilege :function(){
      let dateEffetYear=moment(this.risqueLocal[0].objets.objet_sante_tns.date_effet,"DD/MM/YYYY").year()
      if(this.currentYear+1==dateEffetYear){
        return true
      }
      return false
    },
  },
  watch: {
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.risqueLocal = this.getItemsRisque.ItemsRisque;
        this.arrayProduit()
        this.hasProduct=false
        if(this.risqueLocal.length>0){
          this.risqueLocal.map((risque)=>{
            if(risque.produitsFiltred.length>0 && !this.hasProduct){
              this.hasProduct=true
            }
            this.updateOrderTarification(risque.produitsFiltred)
          })
        }
      },
      immediate: true,
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque;
    },
  },
  created() {
  },
  methods: {
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      console.log(exProduitsDetails, produitSelected);
      let expDetail = exProduitsDetails.find((exp) => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.info : ''
    },
    ifChooseSurComp(){
      if(this.risqueLocal[0].produitsFiltred && this.risqueLocal[0].produitsFiltred.length>0){
        let choosedSurComp=_.find(this.risqueLocal[0].produitsFiltred, (value,key) => value.hasSurComp)
        return choosedSurComp ? true : false
      }else{
        return false
      }
    },
    ifHasSurComp(produit){
      produit.hasSurComp=false
      if(produit.garanties && produit.garanties.length>0){
        produit.garanties.map((gn)=>{
          let result=_.find(gn.garantieniveaux, function(gnr) { return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel=='Oui' });
          if(result){
            produit.hasSurComp=true
          }
        })
      }
      return produit
    },
    hasSurComp(tarif) {
      let surComp = tarif.optionsCompatibles
      return (Array.isArray(surComp) && surComp.length <= 0) ? false : true
    },
    titleClass(word){
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '';
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Complète':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    getGtNvByNiveau(garantie,niveau){
      let tmpGNPOption = null
      garantie.produit_garantie.forEach(element => {
        element.garantiesniveaux.forEach(element1 => {
          if (element1.pivot.libelle == niveau) {
            tmpGNPOption = element1.garantie_niveau_libel
            return tmpGNPOption
          }
        });
      });
      return tmpGNPOption
    },
    updateIndexDevis(value) {
      this.indexRisque=value
      this.$store.commit("setSelectedRisque", {
        selectedRisque: value,
      });
    },
    async onChangeNiveau(event,index,val,gr){
      let produitTmp  =this.risqueLocal[index].produitsFiltred[val]
      let risqueTmp   =this.risqueLocal[index].risque
      let garantiesTmp=this.risqueLocal[index].produitsFiltred[val].garanties
      garantiesTmp[gr].garantieNiveauID[0]=event
      if(this.formatText(risqueTmp.label)=='SANTESENIORS' && produitTmp.produit.assureur.nom_assureur=='SL'){
        let result=_.find(garantiesTmp[gr].garantieniveaux, function(gn) { return gn.id == event && gn.garantie_niveau_libel=='Oui' });
        if(result){
          garantiesTmp.map((gt,key)=>{
            if(key!=0 && key!=gr){
              let resultgr=_.find(gt.garantieniveaux, function(gn) { return  gn.garantie_niveau_libel=='Non' });
              gt.garantieNiveauID[0]=resultgr.id
            }
          })
        }
      }
      let objets=this.risqueLocal[index].objets
      let result=await updateProduitTarif(garantiesTmp,produitTmp,this.risqueLocal[index].risque.label,objets)
      this.risqueLocal[index].produitsFiltred[val].tarif.tarif=result
      let dataLocal={
        id:garantiesTmp[gr].tarificationProduitGtNv,
        garantieNvId:garantiesTmp[gr].garantieNiveauID[0]
      }
      await this.$http.post(`portefeuilles/updateTarificationNiveauGtNv`,dataLocal).then((response)=>{
        if (response.data) {
          console.log('updated')
        }})
    },
    dupliquer(risque,produit){
      let produitD=this.risqueLocal[risque].produits[produit];
      this.risqueLocal[risque].produits.push(produitD);
    },
    selectionnerProduit(risque,produit){
      let produitsCpt = this.risqueLocal[risque].produitsFiltred.filter(pr => pr.tarif.presente);
      if(produitsCpt.length<3){
        this.risqueLocal[risque].produitsFiltred[produit].tarif.presente=true
      }
      if(produitsCpt.length==3){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vous ne pouvez pas sélectionner plus de 3 offres',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    deselectionnerProduit(risque,produit){
      this.risqueLocal[risque].produitsFiltred[produit].tarif.presente=false
      this.risqueLocal[risque].produitsFiltred[produit].tarif.preconise=false
    },

    getPtGtNvExig(risque,produit,exigence,formule){
      let result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule);
      if(result.length>0){
        if(result[0].exigencesniveaux.length> 0){
          let resultFiltre=result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length>0 ?  resultFiltre[0].exigence_niveau_libel: ''
        }
      }else{
        return ''
      }
    },
    getPtGtNvExigCorrespondant(risque,produit,exigence,formule){
      let result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule);
      if(result.length>0){
        if(result[0].exigencesniveaux.length> 0){
          let resultFiltre=result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length>0 ?  resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '': ''
        }
      }else{
        return ''
      }
    },
    getGarantieProduit(garantie,produitGarantie){
      let found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle : ''
    },
    getGarantieProduitCommercial(garantie,produitGarantie){
      let found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle_comm : ''
    },
    comparerExig(ex1,ex2){
      let i=this.getExByScoringNumber(ex1);
      let j=this.getExByScoringNumber(ex2);
      if(i<j) return 1;
      else if(i>j) return -1;
      else return 0;
    },
    getExByScoringNumber (ex1) {
      switch(ex1){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    },
    updateOrderTarification(tarification) {
      console.log(tarification)
      let data = []
      tarification.forEach((el, index) => {
        data.push({ "tarificationId": el.tarif.tarification_id, index })
      })
      this.$http.post('portefeuilles/updateOrderTarification', data).then(response => {
        if (response.data.success) { console.log('updated') }
      }).catch(e => {console.error(e)})
    },
    filtreDownUp(risque){
      this.showCompareTable=false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => a.tarif.tarif.tarif - b.tarif.tarif.tarif);
        this.showCompareTable=true
      }, 10);
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    filtreUpDown(risque){
      this.showCompareTable=false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) =>  b.tarif.tarif.tarif - a.tarif.tarif.tarif);
        this.showCompareTable=true
      }, 10);
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    filtreByTaux(risque,type){
      this.showCompareTable=false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred=type=='UpDown' ? this.sortByTauxDec(this.risqueLocal[risque].produitsFiltred) : this.sortByTauxCr(this.risqueLocal[risque].produitsFiltred)
        this.showCompareTable=true
      }, 10);
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    async submitProduit(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      let produisPresente=[]
      if(this.risqueLocal[this.indexRisque].produitsFiltred.length>0){
        this.risqueLocal[this.indexRisque].produitsFiltred.map((rlp)=>{
          produisPresente.push({
            presente:rlp.tarif.presente,
            taux_commission:rlp.tarif.taux_commission,
            prime:rlp.tarif.tarif.tarif,
            prime_pmss:rlp.tarif.tarif.tarif_pmss,
            tarification_id:rlp.tarif.tarification_id,
          })
        })
      }
      if(produisPresente.length>0){
        let data={
          produitsPresentes:produisPresente,
          devis_id:this.risqueLocal[this.indexRisque].devis.id
        }
        await this.$http.post(`portefeuilles/updateTarifPresente`,data).then((response)=>{
          if (response.data) {
            console.log('updated')
          }})
      }
      this.$store.commit("setStepTarif", {
        step: {
          indexStep:3,
          active:'resume'
        },
      });
    },
    backStep(){
      this.$store.commit("setStepTarif", {
        step: {
          indexStep:1,
          active:'profil'
        },
      });
    },
    getTarif(selected,pg,tarif){
      let found = pg.find(pgv => pgv.id === selected);
      let tmpTarif= found ? tarif.find(tf => tf.formule === found.libelle) : 'NaN'
      return tmpTarif.tarif
    },
    getScore(value){
      return value ? (value*100).toFixed(1): '0'
    },
    arrayProduit(){
      let produitFiltre=[]
      if(this.risqueLocal.length>0){
        this.risqueLocal.map((risque)=>{
          produitFiltre=[]
          risque.produitsFiltred=[]
          risque.produits.map((produit)=>{
            if(produit.tarif){
              produit.tarif.map((tf)=>{
                const { tarif, ...newProduit } = produit;
                let garantiesLocal=tf.garanties
                const {garanties, ...tarifLocal} = tf;
                produitFiltre.push({
                  produit:newProduit,
                  garanties: garantiesLocal,
                  tarif:tarifLocal
                })
              })
            }
          })
          if(produitFiltre.length>0){
            let data={
              produits:produitFiltre,
              exigences:risque.exigences,
              garanties:risque.garanties,
              risque:risque.risque.label,
              objets:risque.objets
            }
            let tmpProduits=selectRisqueAndProduct(data)

            risque.produitsFiltred=this.sortByTauxDec(tmpProduits);
          }
        })
      }
    },
    showFile(id,type){
      this.displayDocument(id,type,type)
    },
    sortByTauxDec(data) {
      return data.sort(function (a, b) {
        return b.tarif.tauxGn - a.tarif.tauxGn || b.tarif.tauxCouv - a.tarif.tauxCouv || a.tarif.tarif.tarif - b.tarif.tarif.tarif
      })
    },
    sortByTauxCr(data) {
      function DownUpcompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return -1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return 1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return -1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return 1
          }
        }
        return 0
      }

      data.sort(DownUpcompare)
      return data
    },
    hasGarantieNiveau(gn,options){
      let tmpOption=_.find(options.optionsCompatibles, (v,key) => key==gn)
      return tmpOption ? true : false
    },
    getExigence(egn,egnArray){
      let tmpOption=_.find(egnArray, (value,key) => value.id==egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    formatText(str) {
      var map = {
        '-' : ' ',
        '-' : '_',
        'a' : 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        'e' : 'é|è|ê|ë|É|È|Ê|Ë',
        'i' : 'í|ì|î|ï|Í|Ì|Î|Ï',
        'o' : 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
      };
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      };
      return str.split(' ').join('').toUpperCase()
    },
    async onChangeTaux(event,index,val){
      this.showLoading=true
      let data={
        tauxCommision:event,
        produitTarif:this.risqueLocal[index].produitsFiltred[val].tarif,
        produitAssureur:this.risqueLocal[index].produitsFiltred[val].produit.assureur.nom_assureur,
        objet:this.risqueLocal[index].objets,
        risque:this.risqueLocal[index].risque,
        assure:this.Tiers
      }
      await this.$http.post(`portefeuilles/getTarifByFormule`,data).then((response)=>{
        if(response.data.statut==201){
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif=response.data.data.tarif
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif_base=response.data.data.tarif_base
          this.risqueLocal[index].produitsFiltred[val].tarif.optionsCompatibles=response.data.data.optionsCompatibles
          let produitTmp  =this.risqueLocal[index].produitsFiltred[val]
          let garantiesTmp=this.risqueLocal[index].produitsFiltred[val].garanties
          let result=updateProduitTarif(garantiesTmp,produitTmp,this.risqueLocal[index].risque.label,this.risqueLocal[index].objets)
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif=result
          this.showLoading=false
        }
      }).catch((err)=>{
        this.showLoading=false
      })
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    getAssureursRisque(produits) {
      var innerHTML = null
      var assureurs = produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0) {
          assureurs.push(pr.assureur.nom_com_assureur + ' (' + pr.produit_nom + ')');
        }
        return assureurs;
      }, []);

      if(assureurs.length > 0){
        innerHTML = `Aucun tarif disponible pour ${assureurs.length == 1 ? 'la compagnie' : 'les companies'} : <strong>${assureurs.join(", ")}</strong>`
      }
      return innerHTML
    },
    msgCodeBbi(produits){
      var innerHTML = null
      let msgError = null
      var assureurs = produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0 && pr.errors) {
          msgError = pr.errors
          console.log(msgError)
            assureurs.push(pr.assureur.nom_com_assureur + ' (' + pr.produit_nom + ')');
        }
        return assureurs;
      }, []);
      if(assureurs.length > 0){
        const hasECA = assureurs.some(element => element.includes('ECA'));
       let erreurCodeBBi ="Vous n'avez pas l'accès à ce courtier"
        if(hasECA && msgError.includes(erreurCodeBBi) )
        innerHTML = `Une erreur avec le code ECA a été détecté, merci de vous rapprocher de notre support`
      }
      return innerHTML
    },
    // ShowFile(document) {
    //   var str = document.path + document.nom
    //   var width = 1000
    //   var height = 600
    //   var left = parseInt(screen.availWidth / 2 - width / 2)
    //   var top = parseInt(screen.availHeight / 2 - height / 2)
    //   var windowFeatures = 'width=' + width + ',height=' + height + ',status,resizable,left=' + left + ',top=' + top + 'screenX=' + left + ',screenY=' + top
    //   window.open(path, 'subWind', windowFeatures)
    // }

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.offere_thClass th {
  background-color: #ebe9f1  !important;
  color: #4d25bc;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard
.wizard-navigation
.wizard-nav
li
.wizard-icon-circle
.wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
.wizard-navigation
.wizard-nav
li.active
a
.checked
~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing
{
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
  padding: 0 !important;
}
.propositions .card-header
{
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight:bold;
  font-size: 1.2rem;
}
.propositions .card button
{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
.box-custom-text{
  color: #7353ca;
}
.custom-tarif{
      background-color: rgb(171 153 223 / 12%) !important;
      padding: 0;
}
</style>
